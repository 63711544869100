import videojs from 'video.js'
import SettingsMenuItem from './SettingsMenuItem'
import { menuTypes } from '../utils/menuTypes'
import { MIN_BITRATE_HD } from '../utils/constants'

const Menu = videojs.getComponent('Menu')

/**
 * Player settings menu content
 *
 * @extends Menu
 * @class SettingsMenu
 */
class SettingsMenu extends Menu {
  constructor (player, options) {
    super(player, options)

    this.attachHeader()
    this.handleQualityLevelsChange()
    this.handlePlayBackRateChange()
    this.addClass('vjs-settings-menu')

    player.qualityLevels().on(['addqualitylevel', 'removequalitylevel', 'change'], () => { this.handleQualityLevelUpdate() })
    this.on(player, 'ratechange', () => { this.handlePlayBackRateChange() })
  }

  handleBlur (event) {
    if (this.hasClass('vjs-lock-showing')) {
      const relatedTarget = event.relatedTarget || document.activeElement

      // Close menu popup when a user clicks outside the menu
      if (!relatedTarget.classList.contains('vjs-menu-item')) {
        const btn = this.menuButton_

        if (btn && btn.buttonPressed_ && relatedTarget !== btn.el().firstChild) {
          btn.unpressButton()
        }
      }
    }
  }

  handleQualityLevelUpdate () {
    this.handleQualityLevelsChange()
  }

  attachHeader () {
    this.headerItem = new SettingsMenuItem(this.player_, { customClass: 'vjs-align-center vjs-header-menu-item' })
    this.headerItem.attachInnerHTML(this.player().localize('Settings'))
    this.addItem(this.headerItem)
  }

  handleQualityLevelsChange () {
    const qualityLevels = this.player().qualityLevels()
    if (qualityLevels.length === 0) {
      return
    }
    if (!this.qualityLevelsItem) {
      this.qualityLevelsItem = new SettingsMenuItem(this.player_, { customClass: 'vjs-space-between', clickCallback: this.clickCallback })
      this.qualityLevelsItem.addCallbackValue(menuTypes.QUALITY)
      this.addItem(this.qualityLevelsItem)
    }

    const selectedItems = this.getEnabledItems()
    if (qualityLevels.length > 0 && qualityLevels[qualityLevels.selectedIndex]) {
      let level
      if (selectedItems.length === 1) {
        level = selectedItems[0]
      } else {
        level = qualityLevels[qualityLevels.selectedIndex]
      }
      let label
      if (level.label && level.label.match(/^[0-9]+p$/) !== null) {
        label = level.label
      } else {
        label = level.height + 'p'
      }

      this.qualityLevelsItem.attachInnerHTML([
        `<span>${this.player().localize('Quality')}</span>`,
        '<span class="vjs-menu-highlight">',
        selectedItems.length === qualityLevels.length
          ? `<span class="vjs-menu-automatic">${this.player().localize('Automatic')}</span>`
          : '',
        ` ${label}</span>`,
        level.bitrate >= MIN_BITRATE_HD
          ? '<span class="vjs-label-hd vjs-menu-highlight">HD</span>'
          : ''
      ].join(''))
    } else {
      this.qualityLevelsItem.attachInnerHTML([
        `<span>${this.player().localize('Quality')}</span>`,
        '<span class="vjs-menu-highlight">',
        `<span class="vjs-menu-automatic">${this.player().localize('Automatic')}</span>`,
        this.player().localize('Automatic'),
        '</span>'
      ].join(''))
    }
  }

  handlePlayBackRateChange () {
    if (!this.playbackRateItem) {
      this.playbackRateItem = new SettingsMenuItem(this.player_, { customClass: 'vjs-space-between', clickCallback: this.clickCallback })
      this.playbackRateItem.addCallbackValue(menuTypes.PLAYBACKRATE)
      this.playbackRateItem.hide()
      this.addItem(this.playbackRateItem)
    }

    if (this.playbackRates().length > 0) {
      const currentPlaySpeedLabel = this.playbackRates().filter(playSpeed => playSpeed.speed === this.player().playbackRate())
      this.playbackRateItem.attachInnerHTML(`<span>${this.player().localize('Speed')}</span><span class="vjs-menu-highlight">${this.player().localize(currentPlaySpeedLabel[0].label)}</span>`)
      this.playbackRateItem.show()
    }
  }

  playbackRates () {
    return this.options_.playbackRates || []
  }

  getEnabledItems () {
    const enabledItems = []
    const qualityLevels = this.player().qualityLevels()
    for (let i = 0; i < qualityLevels.length; i++) {
      if (qualityLevels[i].enabled) {
        enabledItems.push(qualityLevels[i])
      }
    }
    return enabledItems
  }

  clickCallback = (value) => {
    this.menuButton_.refreshActiveMenu(value)
  }
}

Menu.registerComponent('SettingsMenu', SettingsMenu)
export default SettingsMenu
