import videojs from 'video.js'
import '../components/ThumbnailPreviewContainer'
import '../components/CustomMouseTimeDisplay'

const Plugin = videojs.getPlugin('plugin')

class ThumbnailPreview extends Plugin {
  constructor (player, options) {
    super(player)

    this.spriteImageUrl = options.spriteImageUrl
    this.spriteMetadata = options.spriteMetadata

    this.setUp()
  }

  getClosestTile (goal) {
    return this.spriteMetadata.tilePositions.reduce((prev, curr) => (
      (Math.abs(curr.timeCode - goal) < Math.abs(prev.timeCode - goal) ? curr : prev)
    ))
  }

  getSpriteTileMetadata () {
    return this.spriteMetadata.tile
  }

  getSpriteImageUrl () {
    return this.spriteImageUrl
  }

  setUp () {
    if (this.player === null || this.player.el() === null) {
      // Prevent agnoplay from initiating us on a disposed player.
      return
    }

    let controlBar
    if (this.player.advancedControlBar) {
      controlBar = this.player.advancedControlBar
    } else {
      controlBar = this.player.basicControlBar
    }
    if (controlBar && controlBar.progressControl && controlBar.progressControl.seekBar && controlBar.progressControl.seekBar.mouseTimeDisplay) {
      controlBar.progressControl.seekBar.mouseTimeDisplay.addChild('ThumbnailPreviewContainer', {})
    }
  }
}
videojs.registerPlugin('thumbnailPreview', ThumbnailPreview)
