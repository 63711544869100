import videojs from 'video.js'
import './PlayerRelated'
import './SocialShare'

const Component = videojs.getComponent('Component')

/**
 * Player draggable content body control
 *
 * @extends Component
 * @class PlayerDragListenerContent
 */
class PlayerDragListenerContent extends Component {
  constructor (player, options) {
    super(player, options)
    this.setDefaults()

    player.on('enterpictureinpicture', this.addDragEventListeners.bind(this))
    player.on('leavepictureinpicture', this.removeDragEventListeners.bind(this))
  }

  setDefaults () {
    this.active = false
    this.currentX = null
    this.currentY = null
    this.initialX = null
    this.initialY = null
    this.xOffset = 0
    this.yOffset = 0
  }

  addDragEventListeners () {
    this.setDefaults()
    this.on('touchstart', this.dragStart)
    this.on(document, 'touchend', this.dragEnd)
    this.on(document, 'touchmove', this.drag)

    this.on('mousedown', this.dragStart)
    this.on(document, 'mouseup', this.dragEnd)
    this.on(document, 'mousemove', this.drag)
  }

  removeDragEventListeners () {
    this.setDefaults()
    this.off('touchstart', this.dragStart)
    this.off(document, 'touchend', this.dragEnd)
    this.off(document, 'touchmove', this.drag)

    this.off('mousedown', this.dragStart)
    this.off(document, 'mouseup', this.dragEnd)
    this.off(document, 'mousemove', this.drag)
  }

  dragStart (e) {
    if (e.type === 'touchstart') {
      this.initialX = e.touches[0].clientX - this.xOffset
      this.initialY = e.touches[0].clientY - this.yOffset
    } else {
      this.initialX = e.clientX - this.xOffset
      this.initialY = e.clientY - this.yOffset
    }

    if (e.target === this.el()) {
      this.active = true
      this.didDrag = false
    }
  }

  dragEnd (e) {
    this.initialX = this.currentX
    this.initialY = this.currentY

    this.active = false
  }

  drag (e) {
    if (this.active) {
      this.didDrag = true
      e.preventDefault()

      if (e.type === 'touchmove') {
        this.currentX = e.touches[0].clientX - this.initialX
        this.currentY = e.touches[0].clientY - this.initialY
      } else {
        this.currentX = e.clientX - this.initialX
        this.currentY = e.clientY - this.initialY
      }

      this.xOffset = this.currentX
      this.yOffset = this.currentY

      this.setTranslate(this.currentX, this.currentY, this.player().el())
    }
  }

  setTranslate (xPos, yPos, el) {
    el.style.transform = 'translate3d(' + xPos + 'px, ' + yPos + 'px, 0)'
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'agnoplay-drag-element'
  }

  dispose () {
    super.dispose()
    this.removeDragEventListeners()
  }
}

Component.registerComponent('PlayerDragListenerContent', PlayerDragListenerContent)
export default PlayerDragListenerContent
