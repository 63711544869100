import videojs from 'video.js'
import { SEEK_STEP_DEFAULT, SEEK_STEP_PODCAST_DEFAULT } from '../vars'

const Component = videojs.getComponent('Component')
const Button = videojs.getComponent('Button')

/**
 * Player Previous Button
 *
 * @extends Button
 * @class PlayerPreviousButton
 */
class PlayerPreviousButton extends Button {
  constructor (player, options) {
    options = videojs.obj.merge({
      seekStep: options.playerOptions.audio ? SEEK_STEP_PODCAST_DEFAULT : SEEK_STEP_DEFAULT
    }, options)
    super(player, options)
  }

  buildCSSClass () {
    return 'agnoplayer-previous-button'
  }

  createEl (tag = 'button', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const element = super.createEl(tag, props, attributes)
    element.innerHTML = '<span class="agnoplayer-seek-time">' + this.options_.seekStep + '</span>'
    return element
  }

  handleClick (event) {
    event.preventDefault()
    event.stopPropagation()

    this.player().seekBackward(this.options_.seekStep)
    this.animateButton(event)
  }

  animateButton (event) {
    const source = event.target.closest('.' + this.buildCSSClass())
    source.classList.add('active')
    setTimeout(() => {
      source.classList.remove('active')
    }, 200)
  }
}

Component.registerComponent('PlayerPreviousButton', PlayerPreviousButton)
export default PlayerPreviousButton
