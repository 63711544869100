import videojs from 'video.js'

const Component = videojs.getComponent('Component')

class AdCount extends Component {
  constructor (player, options) {
    super(player, options)
    if (!player.hasPlugin('adsManager')) {
      return
    }
    player.on('adbegin', (event, adEvent) => {
      this.currentAd = adEvent.getAd()
      if (this.currentAd.isLinear()) {
        this.update()
      }
    })

    this.update.bind(this)
  }

  update () {
    let totalAds = 0
    let isPod = false
    let adPosition
    const currentAdPodInfo = this.currentAd.getAdPodInfo()
    if (currentAdPodInfo) {
      isPod = true
      adPosition = currentAdPodInfo.getAdPosition()
      totalAds = currentAdPodInfo.getTotalAds()
    }

    let podCount = ''
    if (isPod && (totalAds > 1)) {
      podCount = `${adPosition} / ${totalAds}`
    }

    this.el().innerHTML = podCount
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-ad-count'
  }
}

Component.registerComponent('AdCount', AdCount)
export default AdCount
