import videojs from 'video.js'
import './PlayerRelated'
import './SocialShare'

const Component = videojs.getComponent('Component')

/**
 * Related Content control
 *
 * @extends Component
 * @class PlayerHeader
 */
class PlayerHeader extends Component {
  constructor (player, options) {
    super(player, options)
    if (this.children().length === 0) {
      player.addClass('vjs-header-hidden')
      this.hide()
    }
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-control-bar vjs-header-bar'
  }
}

Component.registerComponent('PlayerHeader', PlayerHeader)
export default PlayerHeader
