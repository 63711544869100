import videojs from 'video.js'
import './CustomProgressControl'
import './TimeTooltip'

const Component = videojs.getComponent('Component')
const ControlBar = videojs.getComponent('ControlBar')
const Dom = videojs.dom

/**
 * Base ControlBar component
 *
 * @extends ControlBar
 * @class BaseControlBar
 */
class BaseControlBar extends ControlBar {
  constructor (player, options) {
    super(player, options)
    if (options.showProgressBarInMenu) {
      this.addClass('progress-in-menu')
    }
  }

  initChildren () {
    super.initChildren()
    // This adds the element as the first child after all other children have been created
    const shadow = Dom.createEl('div', {
      className: 'vjs-controlbar-shadow'
    })
    Dom.prependTo(shadow, this.el_)
  }
}

BaseControlBar.prototype.options_ = {
  children: []
}

Component.registerComponent('BaseControlBar', BaseControlBar)
export default BaseControlBar
