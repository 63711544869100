import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
* Audio-only header
*
* @extends Component
* @class LiveOfflineWrapper
*/
class LiveOfflineWrapper extends Component {
  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const el = super.createEl(tag, props, attributes)
    el.style.backgroundImage = `url('${this.options().poster}')`
    this.player_.addClass('agnoplayer-offline')

    const content = Dom.createEl('h2', {
      textContent: this.options().liveOfflineMessage,
      className: 'agnoplayer-offline-message'
    })

    Dom.insertContent(el, content)
    return el
  }

  buildCSSClass () {
    return 'live-offline-wrapper'
  }
}

Component.registerComponent('LiveOfflineWrapper', LiveOfflineWrapper)
export default LiveOfflineWrapper
