import videojs from 'video.js'
import ModalContainer from './ModalContainer'

const Button = videojs.getComponent('Button')
const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * ContainerButton
 *
 * @extends Button
 * @class ContainerButton
 */
class ContainerButton extends Button {
  constructor (player, options) {
    super(player, options)
    this.update()
  }

  update () {
    const container = this.createContainer()
    if (this.container) {
      this.container.dispose()
      this.player_.removeChild(this.container)
    }
    this.container = container
    this.container.hide()
    this.player_.addChild(this.container)

    if (this.items && this.items.length <= this.hideThreshold_) {
      this.hide()
    } else {
      this.show()
    }
  }

  createContainer () {
    // Propagate the wasPlaying property when re-creating the container.
    const container = new ModalContainer(this.player_, { wasPlaying: (this.container && this.container.wasPlaying_) || false })
    this.hideThreshold_ = 0

    this.title_ = this.title_ || this.options_.title
    if (this.title_) {
      this.titleEl_ = Dom.createEl('li', {
        className: 'vjs-menu-title',
        innerHTML: this.localize(this.title_),
        tabIndex: -1
      })

      this.hideThreshold_ += 1

      const titleComponent = new Component(this.player_, { el: this.titleEl_ })

      container.addItem(titleComponent)
    }

    this.items = this.createItems()
    if (this.items) {
      for (let i = 0; i < this.items.length; i++) {
        container.addItem(this.items[i])
      }
    }

    return container
  }

  createEl (tag, props = {}, attributes = {}) {
    return super.createEl('div', {
      className: this.buildCSSClass()
    }, {

    })
  }

  buildCSSClass () {
    return 'vjs-container-button vjs-button vjs-control'
  }

  /**
   * @abstract
   */
  createItems () { }

  handleClick (event) {
    const wasPlaying = !this.player_.paused()
    this.container.wasPlaying_ = wasPlaying
    if (wasPlaying) {
      this.player_.pause()
    }
    this.container.show()
  }
}

Component.registerComponent('ContainerButton', ContainerButton)
export default ContainerButton
