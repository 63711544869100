import videojs from 'video.js'
import TrackContainerButton from './TrackContainerButton'

const Component = videojs.getComponent('Component')
const AudioTrackMenuItem = videojs.getComponent('AudioTrackMenuItem')

class AudioTrackContainerButton extends TrackContainerButton {
  constructor (player, options) {
    options.tracks = player.audioTracks()
    super(player, options)
  }

  buildCSSClass () {
    return `vjs-audio-button ${super.buildCSSClass()}`
  }

  createItems (items = []) {
    this.hideThreshold_ = 1
    const tracks = this.player_.audioTracks()
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i]

      items.push(new AudioTrackMenuItem(this.player_, {
        track,
        selectable: true,
        multiSelectable: false
      }))
    }

    return items
  }
}

AudioTrackContainerButton.prototype.controlText_ = 'Audio Track'
AudioTrackContainerButton.prototype.options_ = {
  title: 'Audio Track'
}

Component.registerComponent('AudioTrackContainerButton', AudioTrackContainerButton)
export default AudioTrackContainerButton
