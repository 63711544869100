import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

class ControlbarShadow extends Component {
  buildCSSClass () {
    return 'agnoplayer-controlbar-shadow'
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    const el = super.createEl('div', {
      className: this.buildCSSClass()
    })

    const shadowTopEl_ = Dom.createEl('div', {
      className: 'agnoplayer-controlbar-shadow-top'
    })

    const shadowBottomEl_ = Dom.createEl('div', {
      className: 'agnoplayer-controlbar-shadow-bottom'
    })

    el.appendChild(shadowTopEl_)
    el.appendChild(shadowBottomEl_)
    return el
  }
}

Component.registerComponent('ControlbarShadow', ControlbarShadow)
export default ControlbarShadow
/**/
