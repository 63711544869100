import videojs from 'video.js'

/* eslint quote-props: ["error", "as-needed", { "keywords": true, "unnecessary": false }] */
videojs.addLanguage('nl', {
  'Audio Player': 'Audiospeler',
  'Video Player': 'Videospeler',
  'Play': 'Afspelen',
  'Stop': 'Stop',
  'Next': 'Volgende',
  'Pause': 'Pauzeren',
  'Replay': 'Opnieuw',
  'Current Time': 'Huidige tijd',
  'Duration': 'Tijdsduur',
  'Remaining Time': 'Resterende tijd',
  'Stream Type': 'Streamtype',
  'LIVE': 'LIVE',
  'Loaded': 'Geladen',
  'Progress': 'Voortgang',
  'Progress Bar': 'Voortgangsbalk',
  'progress bar timing: currentTime={1} duration={2}': '{1} van {2}',
  'Fullscreen': 'Volledig scherm',
  'Non-Fullscreen': 'Geen volledig scherm',
  'Mute': 'Geluid uit',
  'Unmute': 'Geluid aan',
  'Playback Rate': 'Afspeelsnelheid',
  'Subtitles': 'Ondertiteling',
  'subtitles off': 'Geen',
  'Captions': 'Bijschriften',
  'captions off': 'bijschriften uit',
  'Chapters': 'Hoofdstukken',
  'Descriptions': 'Beschrijvingen',
  'descriptions off': 'beschrijvingen uit',
  'Audio Track': 'Audiospoor',
  'Volume Level': 'Geluidsniveau',
  'You aborted the media playback': 'U heeft het afspelen van de media afgebroken',
  'A network error caused the media download to fail part-way.': 'Een netwerkfout heeft ervoor gezorgd dat het downloaden van de media halverwege is mislukt.',
  'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Sorry, deze video is momenteel niet beschikbaar. Probeer het later nog eens.',
  'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Het afspelen van de media is afgebroken doordat de media gegevens zijn beschadigd of doordat de gebruikte media functies niet door uw browser worden ondersteund.',
  'No compatible source was found for this media.': 'Er is geen geschikte bron voor deze media gevonden.',
  'The media is encrypted and we do not have the keys to decrypt it.': 'De media is versleuteld en we hebben de sleutels niet om deze te ontsleutelen.',
  'Play Video': 'Video afspelen',
  'Back': 'Terug',
  'Close': 'Sluiten',
  'Close Modal Dialog': 'Extra venster sluiten',
  'Modal Window': 'Extra venster',
  'This is a modal window': 'Dit is een extra venster',
  'This modal can be closed by pressing the Escape key or activating the close button.': 'Dit venster kan worden gesloten door op de Escape-toets te drukken of door de sluiten-knop te activeren.',
  ', opens captions settings dialog': ', opent instellingen-venster voor bijschriften',
  ', opens subtitles settings dialog': ', opent instellingen-venster voor ondertitelingen',
  ', opens descriptions settings dialog': ', opent instellingen-venster voor beschrijvingen',
  ', selected': ', geselecteerd',
  'captions settings': 'bijschriften-instellingen',
  'subtitles settings': 'ondertiteling-instellingen',
  'descriptions settings': 'beschrijvingen-instellingen',
  'Text': 'Tekst',
  'White': 'Wit',
  'Black': 'Zwart',
  'Red': 'Rood',
  'Green': 'Groen',
  'Blue': 'Blauw',
  'Yellow': 'Geel',
  'Magenta': 'Magenta',
  'Cyan': 'Cyaan',
  'Background': 'Achtergrond',
  'Window': 'Venster',
  'Transparent': 'Transparant',
  'Semi-Transparent': 'Semi-transparant',
  'Opaque': 'Ondoorzichtig',
  'Font Size': 'Lettergrootte',
  'Text Edge Style': 'Stijl tekstrand',
  'None': 'Geen',
  'Raised': 'Verhoogd',
  'Depressed': 'Ingedrukt',
  'Uniform': 'Uniform',
  'Dropshadow': 'Schaduw',
  'Font Family': 'Lettertype',
  'Proportional Sans-Serif': 'Proportioneel sans-serif',
  'Monospace Sans-Serif': 'Monospace sans-serif',
  'Proportional Serif': 'Proportioneel serif',
  'Monospace Serif': 'Monospace serif',
  'Casual': 'Luchtig',
  'Script': 'Script',
  'Small Caps': 'Kleine hoofdletters',
  'Reset': 'Herstellen',
  'restore all settings to the default values': 'alle instellingen naar de standaardwaarden herstellen',
  'Done': 'Klaar',
  'Caption Settings Dialog': 'Venster voor bijschriften-instellingen',
  'Beginning of dialog window. Escape will cancel and close the window.': 'Begin van dialoogvenster. Escape zal annuleren en het venster sluiten.',
  'End of dialog window.': 'Einde van dialoogvenster.',
  'Watch this video': 'Bekijk deze video',
  'Listen to this audio': 'Luister naar deze audio',
  'Also watch': 'Kijk ook',
  'Advertisement': 'Advertentie',
  'Related': 'Gerelateerd',
  'Social Share': 'Delen',
  'Direct link': 'Kopieer URL',
  'Open Chromecast menu': 'Start Casting',
  'Start AirPlay': 'Start Casting',
  'Normal': 'Normaal',
  'Automatic': 'Automatisch',
  'Settings': 'Instellingen',
  'Quality': 'Kwaliteit',
  'Speed': 'Snelheid',
  'Up Next': 'Volgende',
  'Copy to clipboard': 'Kopieer naar klembord',
  'Copied': 'Gekopieerd',
  'Your video will be interrupted for a short moment in': 'Jouw video wordt even onderbroken over',
  'seconds': 'seconden',
  'Your video will start soon': 'De video start zometeen',
  'Your audio will start soon': 'De audio start zometeen',
  'More': 'Meer',
  'Share': 'Deel',
  'Share this video': 'Deel deze video',
  'Share this podcast': 'Deel deze podcast'
})
