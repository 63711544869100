(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("muxjs"), require("videojs"));
	else if(typeof define === 'function' && define.amd)
		define(["muxjs", "videojs"], factory);
	else if(typeof exports === 'object')
		exports["agnoplayer"] = factory(require("muxjs"), require("videojs"));
	else
		root["agnoplayer"] = factory(root["muxjs"], root["videojs"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__177__, __WEBPACK_EXTERNAL_MODULE__496__) {
return 