import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const CurrentTimeDisplay = videojs.getComponent('CurrentTimeDisplay')

class CustomCurrentTimeDisplay extends CurrentTimeDisplay {
  constructor (player, options = {}) {
    super(player, options)
  }

  createEl () {
    const el = super.createEl()

    this.contentEl_.setAttribute('translate', 'no')

    return el
  }
}

Component.registerComponent('CurrentTimeDisplay', CustomCurrentTimeDisplay)
