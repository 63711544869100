import videojs from 'video.js'
import './AdPlayProgressBar'

const Component = videojs.getComponent('Component')
const Slider = videojs.getComponent('Slider')

class AdProgressBar extends Slider {
  createEl () {
    return super.createEl('div', {
      className: this.buildCSSClass()
    })
  }

  buildCSSClass () {
    return 'vjs-ad-progress-bar vjs-progress-holder'
  }

  update (currentTime, duration) {
    this.percent_ = currentTime / duration
    super.update()
  }

  getPercent () {
    return this.percent_
  }
}

AdProgressBar.prototype.options_ = {
  children: [
    'AdPlayProgressBar'
  ],
  barName: 'adPlayProgressBar'
}

Component.registerComponent('AdProgressBar', AdProgressBar)
