import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const FullscreenToggle = videojs.getComponent('FullscreenToggle')

/**
 * Custom FullscreenToggle component
 *
 * @extends FullscreenToggle
 * @class CustomFullscreenToggle
 */
class CustomFullscreenToggle extends FullscreenToggle {
  constructor (player, options) {
    super(player, options)
    // Quickfix for label change when ESC is used to exit fullscreen - https://github.com/videojs/video.js/issues/5814
    this.on(player, 'fullscreenchange', () => { this.setTimeout(this.handleFullscreenChange, 1) })
  }
}

Component.registerComponent('FullscreenToggle', CustomFullscreenToggle)
export default CustomFullscreenToggle
