import videojs from 'video.js'

const Component = videojs.getComponent('Component')

class AdVideoAnnouncement extends Component {
  constructor (player, options) {
    super(player, options)
    if (!player.hasPlugin('adsManager')) {
      return
    }
    this.on(player, 'adsready', () => {
      if (typeof (player.ima) === 'undefined') {
        return
      }
      this.show()
    })

    this.hide()
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const el = super.createEl(tag, props, attributes)
    el.innerHTML = this.options().isAudioPlayer
      ? this.player().localize('Your audio will start soon')
      : this.player().localize('Your video will start soon')
    return el
  }

  buildCSSClass () {
    return 'vjs-ad-video-announcement'
  }
}

Component.registerComponent('AdVideoAnnouncement', AdVideoAnnouncement)
export default AdVideoAnnouncement
