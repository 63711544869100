const VAST_URL = 'http://pubads.g.doubleclick.net/gampad/ads'
const getVastUrl = (config) => {
  const videoTargeting = {
    ...config.gamVideoTargeting,
    ...config.gamTargeting
  }
  const adType = config.audioPlayer ? 'audio' : 'video'
  let vastUrl =
    `${VAST_URL}` +
    `?sz=${config.gamVideoSize}` +
    `&iu=${config.gamAdUnitId}` +
    `&ad_type=${adType}` +
    '&impl=s' +
    '&gdfp_req=1' +
    '&env=vp' +
    '&output=vast' +
    '&unviewed_position_start=1' +
    `&url=${window.location.href}` +
    `&description_url=${window.location.href}` +
    `&correlator=${new Date().getTime()}` +
    '&ad_rule=1' + // Set back to One. Never complete this
    `&vid=${config.videoId}` +
    '&vpa=1' +
    '&vpmute=0'

  if (config.gamCmsId) {
    vastUrl += `&cmsid=${config.gamCmsId}`
  }

  if (config.gamNoPersonalisedAds != null) { // 0 is a valid value
    vastUrl += `&npa=${config.gamNoPersonalisedAds}`
  }

  if (videoTargeting) {
    const tmpCustomParams = Object.keys(videoTargeting)
      .map((key) => `${key}=${videoTargeting[key]}`)
      .filter(Boolean)

    if (tmpCustomParams.length) {
      vastUrl += `&cust_params=${encodeURIComponent(
        tmpCustomParams.join('&')
      )}`
    }
  }

  return vastUrl
}

export default getVastUrl
