import videojs from 'video.js'
import './AdPlayToggle'
import './AdCurrentTimeDisplay'
import './AdDurationDisplay'
import './AdProgressControl'
import './AdLabel'
import './AdCount'
import './AdVideoAnnouncement'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * AdControlBar
 *
 * @extends ControlBar
 * @class AdControlBar
 */
class AdControlBar extends Component {
  constructor (player, options) {
    // we need to do this before calling 'super' to make sure the component is initialized correctly
    if (player.hasAdvancedTheme() && !(videojs.browser.IS_IPAD || videojs.browser.IS_IPHONE || videojs.browser.IS_ANDROID)) {
      options.volumePanel = {
        inline: false,
        volumeControl: {
          vertical: true
        }
      }
    }
    super(player, options)

    this.on(player, 'adstart', this.handleAdStart.bind(this))
    this.on(player, 'adend', this.handleAdEnd.bind(this))
    this.on(this.player_, 'volumechange', this.handleVolumeChange)
    this.on(player, 'adpaused', this.handleAdPaused)
    this.on(player, 'adresumed', this.handleAdResume)

    if (options.hideDuringAds === 'always-hide') {
      this.hide()
    } else if (options.hideDuringAds === 'label-only') {
      this.addClass('ad-control-label-only')
    } else if (options.hideDuringAds === 'hide-on-inactive') {
      this.addClass('ad-control-hide-on-inactive')
    }

    this.inAdBreak_ = false
  }

  createEl () {
    return super.createEl('div', {
      className: this.buildCSSClass(),
      dir: 'ltr'
    })
  }

  initChildren () {
    super.initChildren()
    // This adds the element as the first child after all other children have been created
    // TODO duplication between this and CustomControlBar.
    const shadow = Dom.createEl('div', {
      className: 'vjs-controlbar-shadow'
    })
    Dom.prependTo(shadow, this.el_)
  }

  buildCSSClass () {
    return 'vjs-control-bar vjs-ad-control-bar'
  }

  handleAdStart () {
    if (this.inAdBreak_) {
      return
    }
    this.inAdBreak_ = true
    this.controller = this.player_.ima.controller
    // Store the original AdUi
    this.adUi_ = this.controller.adUi
    this.controller.adUi = this
    this.controller.addEventListener('click', this.handleAdClick.bind(this))
  }

  handleAdClick () {
    if (typeof (this.controller) === 'undefined') {
      return
    }

    this.controller.pauseAd()
    this.show()
  }

  handleAdEnd () {
    this.inAdBreak_ = false
    // Restore AdUi
    this.controller.adUi = this.adUi_
    this.adUi = null
    this.controller = null
    this.hideAdContainer()
  }

  handleVolumeChange () {
    if (!this.inAdBreak_) {
      return
    }
    this.controller.sdkImpl.setVolume(this.player_.muted() ? 0 : this.player_.volume())
  }

  handleAdPaused () {
    this.player().addClass('agnoplayer-ad-paused')
  }

  handleAdResume () {
    this.player().removeClass('agnoplayer-ad-paused')
    if (this.options_.hideDuringAds === 'always-hide') {
      this.hide()
    }
  }

  // AdUI Events
  /**
   * @param {number} currentTime Current time of the ad.
   * @param {number} remainingTime Remaining time of the ad.
   * @param {number} duration Duration of the ad.
   * @param {number} adPosition Index of the ad in the pod.
   * @param {number} totalAds Total number of ads in the pod.
   */
  updateAdUi (currentTime, remainingTime, duration, adPosition, totalAds) {
    this.adCurrentTimeDisplay.update(currentTime)
    this.adDurationDisplay.update(duration)
    this.adProgressControl.adProgressBar.update(currentTime, duration)
  }

  onAdBreakStart () {
    this.showAdContainer()
    this.onAdsPlaying()
  }

  onAdBreakEnd () {
    this.adUi_.onAdBreakEnd()

    const currentAd = this.controller.getCurrentAd()
    if (currentAd == null || // hide for post-roll only playlist
        currentAd.isLinear()) { // don't hide for non-linear ads
      this.hideAdContainer()
    }
  }

  onAllAdsCompleted () {
    this.adUi_.hideAdContainer()
    this.hideAdContainer()
  }

  onPlayerEnterFullscreen () {
    // noop
  }

  onPlayerExitFullscreen () {
    // noop
  }

  onAdsResumed () {
    this.onAdsPlaying()
  }

  onAdsPlaying () {
    this.handleAdResume()
    this.adPlayToggle.handlePlay()
  }

  onAdsPaused () {
    this.handleAdPaused()
    this.adPlayToggle.handlePause()
  }

  onAdError () {
    this.hideAdContainer()
  }

  onLinearAdStart () {
    this.handleAdStart()
  }

  showAdContainer () {
    this.el_.style.display = 'flex'
    this.adUi_.showAdContainer()
  }

  hideAdContainer () {
    this.el_.style.display = 'none'
  }

  reset () {
    this.hideAdContainer()
  }
}

AdControlBar.prototype.options_ = {
  hideDuringAds: 'always-show',
  children: [
    'adPlayToggle',
    (videojs.browser.IS_IPAD || videojs.browser.IS_IPHONE || videojs.browser.IS_ANDROID) ? 'muteToggle' : 'volumePanel',
    'adCurrentTimeDisplay',
    'timeDivider',
    'adDurationDisplay',
    'adProgressControl',
    'customControlSpacer',
    'adLabel',
    'adCount',
    'adVideoAnnouncement'
  ]
}

Component.registerComponent('AdControlBar', AdControlBar)
export default AdControlBar
