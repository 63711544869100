import videojs from 'video.js'

const TimeTooltip = videojs.getComponent('TimeTooltip')
const Component = videojs.getComponent('Component')

class CustomTimeTooltip extends TimeTooltip {
  constructor (player, options) {
    super(player, options)
    this.hide()
  }

  update (seekBarRect, seekBarPoint, content) {
    super.update(seekBarRect, seekBarPoint, content)
    if (seekBarRect.left > 0) {
      this.show()
    }
  }
}

Component.registerComponent('TimeTooltip', CustomTimeTooltip)
export default CustomTimeTooltip
