import videojs from 'video.js'
import * as Fn from '../utils/fn.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom
const FormatTime = videojs.formatTime

/**
 *  Live Edge Time Display component
 *
 * @extends Component
 * @class LiveEdgeTimeDisplay
 */
class LiveEdgeTimeDisplay extends Component {
  constructor (player, options) {
    super(player, options)
    if (player.liveTracker) {
      this.setEventHandlers_()
    }
  }

  setEventHandlers_ () {
    this.updateContent = Fn.throttle(Fn.bind(this, this.updateContent), Fn.UPDATE_REFRESH_INTERVAL)

    this.updateInterval = null

    this.on(this.player_, ['playing'], this.enableInterval_)
    this.on(this.player_, ['ended'], this.disableInterval_)

    if ('hidden' in document && 'visibilityState' in document) {
      this.on(document, 'visibilityChange', this.toggleVisibility_)
    }
  }

  toggleVisibility_ (e) {
    if (document.hidden) {
      this.disableInterval_()
    } else {
      this.enableInterval_()
      this.updateContent()
    }
  }

  enableInterval_ () {
    if (this.updateInterval) {
      return
    }

    this.updateInterval = this.setInterval(this.updateContent, Fn.UPDATE_REFRESH_INTERVAL)
  }

  disableInterval_ () {
    if (!this.updateInterval) {
      return
    }

    this.clearInterval(this.updateInterval)
    this.updateInterval = null
  }

  createEl () {
    const el = super.createEl('button', {
      className: this.buildCSSClass()
    })

    this.textEl_ = Dom.createEl('span', {
      className: 'vjs-time-to-edge-display'
    })

    el.appendChild(this.textEl_)
    return el
  }

  updateContent (event) {
    if (!this.player_.liveTracker || !this.player_.liveTracker.isTracking() || this.player_.liveTracker.atLiveEdge()) {
      this.player_.removeClass('agnoplayer-live-edge-time')
      this.hide()
    } else {
      const time = Math.abs(this.player().liveTracker.liveCurrentTime() - this.player().currentTime())
      this.textEl_.innerHTML = `-${FormatTime(time)}`
      this.player_.addClass('agnoplayer-live-edge-time')
      this.show()
    }
  }

  buildCSSClass () {
    return 'vjs-time-to-edge vjs-time-control vjs-control'
  }

  dispose () {
    if (this.player().liveTracker) {
      this.clearInterval()
    }
    this.textEl_ = null

    super.dispose()
  }
}

Component.registerComponent('LiveEdgeTimeDisplay', LiveEdgeTimeDisplay)
export default LiveEdgeTimeDisplay
