/* global GemiusPlayer */
import videojs from 'video.js'
import window from 'global/window'

const Plugin = videojs.getPlugin('plugin')

const CIMTrackerState = {
  EMPTY: 'empty',
  PLAYING: 'playing',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  BUFFERING: 'buffering',
  SEEKING: 'seeking',
  COMPLETE: 'complete'
}

class CIMTracker extends Plugin {
  constructor (player, options) {
    super(player)
    this.options_ = options

    if (this.options_) {
      this.gemiusPlayerPending(window, 'GemiusPlayer')
      this.gemiusPlayerPending(GemiusPlayer.prototype, 'newProgram')
      this.gemiusPlayerPending(GemiusPlayer.prototype, 'newAd')
      this.gemiusPlayerPending(GemiusPlayer.prototype, 'adEvent')
      this.gemiusPlayerPending(GemiusPlayer.prototype, 'programEvent')
      this.gemiusPlayerPending(GemiusPlayer.prototype, 'setVideoObject')

      if (!window.GemiusPlayer) {
        throw new Error('GemiusPlayer is not defined. Were all libraries loaded as expected?')
      }

      this.gemiusPlayer = new GemiusPlayer(this.options_.playerId, this.options_.identifier)
      this.gemiusPlayer.setVideoObject(player.playerEl)

      this.player.on('loadedmetadata', () => { this.onLoadedMetaData() })
      this.player.on('playing', () => { this.onPlaying() })
      this.player.on('pause', () => { this.onPause() })
      this.player.on('waiting', () => { this.onWaiting() })
      this.player.on('seeked', () => { this.onSeeked() })
      this.player.on('ended', () => { this.onEnded() })
      this.player.on('sourcechange', () => { this.onSourceChange() })
      this.player.on('timeupdate', () => { this.onTimeupdate() })

      window.addEventListener('beforeunload', () => { this.onBeforeUnload() })
    }
  }

  getLastKnownTimestamp () {
    let timestamp
    const currentTime = this.player.currentTime()
    const duration = this.player.duration()

    if (currentTime && currentTime > duration) {
      timestamp = duration
    } else {
      timestamp = currentTime
    }

    return Math.floor(timestamp)
  }

  gemiusPlayerPending (obj, fun) {
    obj[fun] = obj[fun] || function () {
      const x = window.gemius_player_data = window.gemius_player_data || []
      x[x.length] = [this, fun, arguments]
    }
  }

  onLoadedMetaData () {
    if (this.state_ !== CIMTrackerState.EMPTY) {
      return
    }

    if (this.options_.source.metadata && this.options_.source.metadata.cim) {
      this.cim = this.options_.source.metadata.cim
      if (this.cim) {
        this.gemiusPlayer.newProgram(this.cim.programID, this.cim.additionalParameters)
      }
      this.state_ = CIMTrackerState.STOPPED
    }
  }

  onPlaying () {
    if (this.state_ === CIMTrackerState.PLAYING || this.state_ === CIMTrackerState.EMPTY) {
      return
    }

    this.lastKnownTimestamp_ = this.getLastKnownTimestamp()
    if (this.cim) {
      this.gemiusPlayer.programEvent(this.cim.programID, this.lastKnownTimestamp_, 'play')
    }
    this.state_ = CIMTrackerState.PLAYING
  }

  onPause () {
    if (this.state_ === CIMTrackerState.PAUSED || this.state_ === CIMTrackerState.EMPTY) {
      return
    }

    this.lastKnownTimestamp_ = this.getLastKnownTimestamp()
    if (this.cim) {
      this.gemiusPlayer.programEvent(this.cim.programID, this.lastKnownTimestamp_, 'pause')
    }
    this.state_ = CIMTrackerState.PAUSED
  }

  onWaiting () {
    if (this.state_ === CIMTrackerState.BUFFERING || this.state_ === CIMTrackerState.SEEKING || this.state_ === CIMTrackerState.EMPTY) {
      return
    }

    this.lastKnownTimestamp_ = this.getLastKnownTimestamp()
    if (this.cim) {
      this.gemiusPlayer.programEvent(this.cim.programID, this.lastKnownTimestamp_, 'buffer')
    }
    this.state_ = CIMTrackerState.BUFFERING
  }

  onSeeked () {
    if (this.state_ === CIMTrackerState.SEEKING || this.state_ === CIMTrackerState.EMPTY) {
      return
    }
    if (this.cim) {
      this.gemiusPlayer.programEvent(this.cim.programID, this.lastKnownTimestamp_, 'seek')
    }
    this.state_ = CIMTrackerState.SEEKING
  }

  onEnded () {
    if (this.state_ === CIMTrackerState.ENDED || this.state_ === CIMTrackerState.EMPTY) {
      return
    }

    this.lastKnownTimestamp_ = this.getLastKnownTimestamp()
    if (this.cim) {
      this.gemiusPlayer.programEvent(this.cim.programID, this.lastKnownTimestamp_, 'complete')
    }
    this.state_ = CIMTrackerState.ENDED
  }

  onSourceChange () {
    if (this.state_ === CIMTrackerState.EMPTY) {
      return
    }

    if (this.state_ !== CIMTrackerState.ENDED && this.cim) {
      this.gemiusPlayer.programEvent(this.cim.programID, this.lastKnownTimestamp_, 'close')
    }

    this.state_ = CIMTrackerState.EMPTY
  }

  onTimeupdate () {
    this.lastKnownTimestamp_ = this.getLastKnownTimestamp()
  }

  onBeforeUnload () {
    if (this.cim) {
      this.gemiusPlayer.programEvent(this.cim.programID, this.lastKnownTimestamp_, 'close')
    }
    this.state_ = CIMTrackerState.EMPTY
  }
}

CIMTracker.prototype.state_ = CIMTrackerState.EMPTY
CIMTracker.prototype.lastKnownTimestamp_ = 0

videojs.registerPlugin('cimTracker', CIMTracker)
