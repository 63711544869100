import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * Player Title control
 *
 * @extends Component
 * @class PlayerTitle
 */
class PlayerTitle extends Component {
  constructor (player, options) {
    options = videojs.obj.merge({
      titlePosition: 'bottom-left'
    }, options)
    super(player, options)
    this.player().addClass(`agnoplayer-title-${this.options().titlePosition}`)
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const el = super.createEl(tag, props, attributes)

    const content = Dom.createEl('p', {
      textContent: this.options_.posterTitle
    })

    Dom.insertContent(el, content)
    return el
  }

  buildCSSClass () {
    return `vjs-player-title ${this.options().titlePosition}`
  }
}

Component.registerComponent('PlayerTitle', PlayerTitle)
export default PlayerTitle
