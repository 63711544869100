import videojs from 'video.js'
import SettingsMenuItem from './SettingsMenuItem'
import { menuTypes } from '../utils/menuTypes'

const Menu = videojs.getComponent('Menu')

/**
 * Player settings menu for playback speed container
 *
 * @extends Menu
 * @class PlayBackRateSubMenu
 */
class PlayBackRateSubMenu extends Menu {
  constructor (player, options) {
    super(player, options)
    this.refreshPlaySpeedItems()
    this.addClass('vjs-settings-menu')
    this.addClass('vjs-settings-submenu')

    this.on(player, 'ratechange', () => { this.refreshPlaySpeedItems() })
  }

  handleBlur (event) {
    if (this.hasClass('vjs-lock-showing')) {
      const relatedTarget = event.relatedTarget || document.activeElement

      // Close menu popup when a user clicks outside the menu
      if (!relatedTarget.classList.contains('vjs-menu-item')) {
        const btn = this.menuButton_

        if (btn && btn.buttonPressed_ && relatedTarget !== btn.el().firstChild) {
          btn.unpressButton()
        }
      }
    }
  }

  playbackRates () {
    return this.options_.playbackRates || this.options_.playerOptions.playbackRates || []
  }

  refreshPlaySpeedItems () {
    this.removeChildren()
    const headerItem = new SettingsMenuItem(this.player_, { customClass: 'vjs-header-menu-item vjs-align-center', clickCallback: this.clickCallback })
    headerItem.attachInnerHTML(`<span class="vjs-icon-placeholder vjs-menu-pull-left"></span>${this.player().localize('Speed')}`)
    this.addItem(headerItem)

    const rates = this.playbackRates()
    const sortedRates = Array.from(rates).sort((a, b) => { return b.speed - a.speed })
    if (sortedRates) {
      for (let i = sortedRates.length - 1; i >= 0; i--) {
        const playbackRateItem = new SettingsMenuItem(this.player_, { customClass: `vjs-align-center ${this.player().playbackRate() === sortedRates[i].speed ? 'vjs-selected' : ''}`, clickCallback: this.clickCallback })
        playbackRateItem.addCallbackValue(sortedRates[i].speed)
        playbackRateItem.attachInnerHTML(this.player().localize(sortedRates[i].label))
        this.addItem(playbackRateItem)
      }
    }
  }

  clickCallback = (value) => {
    if (value) {
      this.player().playbackRate(value)
    }

    if (this.menuButton_) {
      this.menuButton_.refreshActiveMenu(menuTypes.MAIN)
    }
  }

  removeChildren () {
    if (this.children()) {
      for (let i = this.children().length - 1; i >= 0; i--) {
        this.removeChild(this.children()[i])
      }
    }
  }
}

Menu.registerComponent('PlayBackRateSubMenu', PlayBackRateSubMenu)
export default PlayBackRateSubMenu
