import videojs from 'video.js'

const Button = videojs.getComponent('Button')
const Component = videojs.getComponent('Component')

/**
 * Related Content control
 *
 * @extends Button
 * @class CloseButton
 */
class CloseButton extends Button {
  constructor (player, options) {
    super(player, options)
    this.controlText('Close')
    if (options.buttonClass) {
      this.addClass(options.buttonClass)
    }
  }

  createEl (tag, props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass(),
      innerHTML: `<span class="vjs-player-social-close-button-text">${this.localize('Close')}</span>`
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-component-close top-button vjs-button'
  }

  handleClick (event) {
    event.stopPropagation()

    if (!this.player_.paused()) {
      this.player_.pause()
    }
    this.player_.removeClass(this.options_.targetClass)
    if (this.options_.eventName) {
      this.trigger({ type: this.options_.eventName, bubbles: false })
    }
    if (this.options_.playerEventName) {
      this.player_.trigger(this.options_.playerEventName)
    }
  }
}

Component.registerComponent('CloseButton', CloseButton)
export default CloseButton
