import videojs from 'video.js'
import { MIN_BITRATE_HD } from '../utils/constants'
import * as Fn from '../utils/fn.js'

const Component = videojs.getComponent('Component')
const MenuItem = videojs.getComponent('MenuItem')
const Dom = videojs.dom

class QualityLevelMenuItem extends MenuItem {
  constructor (player, options) {
    options.selected = options.selected || false
    options.selectable = true
    options.multiSelectable = false

    super(player, options)

    const updateHandler = Fn.bind(this, this.update)
    const qualityLevels = player.qualityLevels()
    qualityLevels.on('change', updateHandler)
    player.on('quality_menu_change', updateHandler)
    this.on('dispose', () => {
      qualityLevels.off('change', updateHandler)
      player.off('quality_menu_change', updateHandler)
    })
  }

  createEl (type, props, attrs) {
    this.nonIconControl = true
    const el = super.createEl('li', props, attrs)

    if (this.options_.bitrate >= MIN_BITRATE_HD) {
      el.appendChild(Dom.createEl('span', {
        className: 'vjs-label-hd vjs-menu-highlight',
        textContent: 'HD'
      }))
    }

    if (this.options_.bitrate) {
      el.appendChild(Dom.createEl('span', {
        className: 'vjs-label-bitrate',
        textContent: this.bitrateLabel(this.options_.bitrate)
      }))
    }

    return el
  }

  bitrateLabel (bitrate) {
    let i = -1
    const byteUnits = [' kbps', ' Mbps', ' Gbps']
    do {
      bitrate = bitrate / 1024
      i++
    } while (bitrate > 1024)

    return Math.max(bitrate, 0.1).toFixed(1) + byteUnits[i]
  }

  handleClick (event) {
    super.handleClick()
    const qualityLevels = this.player_.qualityLevels()
    for (let i = 0; i < qualityLevels.length; i++) {
      qualityLevels[i].enabled = (this.options_.label === 'Automatic') ? true : qualityLevels[i].bitrate === this.options_.bitrate && qualityLevels[i].height === this.options_.height
    }
    this.update()
    this.player().trigger('quality_menu_change')
  }

  update (event) {
    const qualityLevels = this.player_.qualityLevels().levels_.filter(qualityLevel => qualityLevel.enabled === true)
    if (qualityLevels.length > 1) {
      this.selected(this.options_.label === 'Automatic')
    } else if (qualityLevels.length === 1) {
      const qualityLevel = qualityLevels[0]
      this.selected(qualityLevel.bitrate === this.options_.bitrate && qualityLevel.height === this.options_.height)
    }
  }
}

QualityLevelMenuItem.prototype.contentElType = 'button'

Component.registerComponent('QualityLevelMenuItem', QualityLevelMenuItem)
export default QualityLevelMenuItem
