import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const TextTrackMenuItem = videojs.getComponent('TextTrackMenuItem')
const Dom = videojs.dom

/**
 * CustomTextTrackMenuItem has an [cc] icon to distinguish captions from subtitles
 * in the SubsCapsMenu.
 *
 * @extends TextTrackMenuItem
 */
class CustomTextTrackMenuItem extends TextTrackMenuItem {
  createEl (type, props, attrs) {
    const el = super.createEl(type, props, attrs)

    if (this.options_.track.kind === 'captions') {
      const item = el.querySelector('.vjs-menu-item-text')

      if (item) {
        item.appendChild(Dom.createEl('span', {
          className: 'vjs-icon-placeholder',
          ariaHidden: true
        }, { }))
        item.appendChild(Dom.createEl('span', {
          className: 'vjs-control-text',
          textContent: this.localize('Captions')
        }))
      }
    }

    el.appendChild(Dom.createEl('label', {
      className: 'switch',
      innerHTML: '<span class="slider round"></span>'
    }))

    return el
  }

  handleClick (event) {
    event.stopImmediatePropagation()
    if (this.isSelected_) {
      this.handleDisableSubs()
    } else {
      super.handleClick(event)
    }
  }

  handleDisableSubs () {
    this.track.mode = 'disabled'
    this.selected(false)
    const tracks = this.player().textTracks()
    let allHidden = true

    for (let i = 0, l = tracks.length; i < l; i++) {
      const track = tracks[i]

      if ((['captions', 'descriptions', 'subtitles'].indexOf(track.kind) > -1) && track.mode === 'showing') {
        allHidden = false
        break
      }
    }

    if (allHidden) {
      this.player_.cache_.selectedLanguage = {
        enabled: false
      }
    }
  }
}

Component.registerComponent('CustomTextTrackMenuItem', CustomTextTrackMenuItem)
export default CustomTextTrackMenuItem
