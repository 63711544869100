import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const PosterImage = videojs.getComponent('PosterImage')
const Dom = videojs.dom

const titleTemplate = (replay, title) => `
<div class="endscreen-replay-group">
  <span class="endscreen-replay-title">${replay}</span>
  <span class="endscreen-replay-icon"></span>
  <span class="endscreen-replay-video-title">${title}</span>
<div>`

class PlayerEndscreenReplay extends PosterImage {
  constructor (player, options) {
    super(player, options)
    player.on('videometadataupdated', () => {
      const videoMetadata = this.player_.videoMetadata()
      if (typeof (videoMetadata) === 'undefined') {
        return
      }
      const title = videoMetadata.title
      if (title) {
        this.titleEl.innerHTML = titleTemplate(player.localize('Replay'), title)
      }
    })
  }

  buildCSSClass () {
    return 'agnoplayer-endscreen-replay'
  }

  createEl () {
    const el = Dom.addClass(
      super.createEl(), this.buildCSSClass()
    )
    this.titleEl = Dom.createEl('div', {
      className: 'agnoplayer-endscreen-title',
      innerHTML: titleTemplate('', this.options_.title)
    })
    Dom.appendContent(el, this.titleEl)

    return el
  }
}

Component.registerComponent('PlayerEndscreenReplay', PlayerEndscreenReplay)
export default PlayerEndscreenReplay
