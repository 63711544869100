import videojs from 'video.js'
import ContainerButton from './ContainerButton'
import * as Fn from '../utils/fn.js'

const Component = videojs.getComponent('Component')

class TrackContainerButton extends ContainerButton {
  constructor (player, options) {
    const tracks = options.tracks
    super(player, options)

    if (this.items.length <= 1) {
      this.hide()
    }

    if (!tracks) {
      return
    }

    const updateHandler = Fn.bind(this, this.update)

    tracks.addEventListener('removetrack', updateHandler)
    tracks.addEventListener('addtrack', updateHandler)
    this.player_.on('ready', updateHandler)
    this.player_.on('dispose', () => {
      tracks.removeEventListener('removetrack', updateHandler)
      tracks.removeEventListener('addtrack', updateHandler)
    })
  }
}

TrackContainerButton.prototype.options_ = {
  title: 'Ondertiteling'
}

Component.registerComponent('TrackContainerButton', TrackContainerButton)
export default TrackContainerButton
