import videojs from 'video.js'

/* eslint quote-props: ["error", "as-needed", { "keywords": true, "unnecessary": false }] */
videojs.addLanguage('fr', {
  'Audio Player': 'Lecteur audio',
  'Video Player': 'Lecteur vidéo',
  'Play': 'Jouer',
  'Stop': 'Arrêt',
  'Next': 'Suivante',
  'Pause': 'Pause',
  'Replay': 'Rejouer',
  'Current Time': 'Heure actuelle',
  'Duration': 'Durée',
  'Remaining Time': 'Temps restant',
  'Stream Type': 'Type de flux',
  'LIVE': 'EN DIRECT',
  'Loaded': 'Chargé',
  'Progress': 'Le progrès',
  'Progress Bar': 'Barre de progression',
  'progress bar timing: currentTime={1} duration={2}': 'minutage de la barre de progression : currentTime={1} duration={2}',
  'Fullscreen': 'Plein écran',
  'Non-Fullscreen': 'Non plein écran',
  'Mute': 'Muet',
  'Unmute': 'Rétablir le son',
  'Playback Rate': 'Taux de lecture',
  'Subtitles': 'Les sous-titres',
  'subtitles off': 'sous-titres désactivés',
  'Captions': 'Légendes',
  'captions off': 'sous-titres désactivés',
  'Chapters': 'Chapitres',
  'Descriptions': 'Descriptifs',
  'descriptions off': 'descriptions désactivées',
  'Audio Track': 'Piste audio',
  'Volume Level': 'Niveau sonore',
  'You aborted the media playback': 'Vous avez interrompu la lecture multimédia',
  'A network error caused the media download to fail part-way.': 'Une erreur réseau a provoqué l\'échec partiel du téléchargement du média.',
  'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Le support n\'a pas pu être chargé, soit parce que le serveur ou le réseau a échoué, soit parce que le format n\'est pas pris en charge.',
  'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'La lecture du média a été interrompue en raison d\'un problème de corruption ou parce que le média a utilisé des fonctionnalités que votre navigateur ne prenait pas en charge.',
  'No compatible source was found for this media.': 'Aucune source compatible n\'a été trouvée pour ce média.',
  'The media is encrypted and we do not have the keys to decrypt it.': 'Le média est crypté et nous n\'avons pas les clés pour le décrypter.',
  'Play Video': 'Lire la vidéo',
  'Back': 'Arrière',
  'Close': 'proche',
  'Close Modal Dialog': 'Fermer la boîte de dialogue modale',
  'Modal Window': 'Fenêtre modale',
  'This is a modal window': 'Ceci est une fenêtre modale',
  'This modal can be closed by pressing the Escape key or activating the close button.': 'Ce modal peut être fermé en appuyant sur la touche Échap ou en activant le bouton de fermeture.',
  ', opens captions settings dialog': ', ouvre la boîte de dialogue des paramètres des sous-titres',
  ', opens subtitles settings dialog': ', ouvre la boîte de dialogue des paramètres des sous-titres',
  ', opens descriptions settings dialog': ', ouvre la boîte de dialogue des paramètres des descriptions',
  ', selected': ', sélectionné',
  'captions settings': 'paramètres des sous-titres',
  'subtitles settings': 'paramètres de sous-titres',
  'descriptions settings': 'descriptions paramètres',
  'Text': 'Texte',
  'White': 'blanche',
  'Black': 'Le noir',
  'Red': 'rouge',
  'Green': 'Vert',
  'Blue': 'Bleu',
  'Yellow': 'Jaune',
  'Magenta': 'Magenta',
  'Cyan': 'Cyan',
  'Background': 'Fond',
  'Window': 'Fenêtre',
  'Transparent': 'Transparent',
  'Semi-Transparent': 'Semi-transparent',
  'Opaque': 'Opaque',
  'Font Size': 'Taille de police',
  'Text Edge Style': 'Style de bord de texte',
  'None': 'Rien',
  'Raised': 'Soulevé',
  'Depressed': 'Déprimé',
  'Uniform': 'Uniforme',
  'Dropshadow': 'Ombre portée',
  'Font Family': 'Famille de polices',
  'Proportional Sans-Serif': 'Sans empattement proportionnel',
  'Monospace Sans-Serif': 'Monospace sans empattement',
  'Proportional Serif': 'Serif proportionnel',
  'Monospace Serif': 'Monospace Serif',
  'Casual': 'Décontractée',
  'Script': 'Scénario',
  'Small Caps': 'Lettres minuscules',
  'Reset': 'Réinitialiser',
  'restore all settings to the default values': 'restaurer tous les paramètres aux valeurs par défaut',
  'Done': 'Terminé',
  'Caption Settings Dialog': 'Boîte de dialogue Paramètres de sous-titres',
  'Beginning of dialog window. Escape will cancel and close the window.': 'Début de la fenêtre de dialogue. Escape annulera et fermera la fenêtre.',
  'End of dialog window.': 'Fin de la fenêtre de dialogue.',
  'Watch this video': 'Regarde cette video',
  'Listen to this audio': 'Écoute cet audio',
  'Also watch': 'Regardez aussi',
  'Advertisement': 'Publicité',
  'Related': 'En rapport',
  'Social Share': 'Partage Social',
  'Direct link': 'Lien direct',
  'Open Chromecast menu': 'Ouvrir le menu Chromecast',
  'Start AirPlay': 'Démarrer AirPlay',
  'Normal': 'Normal',
  'Automatic': 'Automatique',
  'Settings': 'Paramètres',
  'Quality': 'Qualité',
  'Speed': 'La vitesse',
  'Up Next': 'Suivant',
  'Copy to clipboard': 'Copier dans le presse-papier',
  'Copied': 'Copié',
  'Your video will be interrupted for a short moment in': 'Votre vidéo sera interrompue pendant un court instant dans',
  'seconds': 'secondes',
  'Your video will start soon': 'La vidéo débutera dans quelques secondes',
  'Your audio will start soon': 'Votre audio va bientôt commencer',
  'More': 'Suite',
  'Share': 'Partager',
  'Share this video': 'Partage cette vidéo',
  'Share this podcast': 'Partager ce podcast'
})
