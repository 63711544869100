import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

class AdLabel extends Component {
  createEl (tag = 'div', props = {}, attributes = {}) {
    const el = super.createEl('div', {
      className: `${this.buildCSSClass()} vjs-control`,
      innerHTML: `<span class="vjs-control-text" role="presentation">${this.localize(this.controlText)}\u00a0</span>`
    })

    this.contentEl_ = Dom.createEl('span', {
      className: 'ad-label-display',
      textContent: this.localize(this.labelText_)
    })

    el.appendChild(this.contentEl_)
    return el
  }

  buildCSSClass () {
    return 'vjs-ad-label'
  }

  dispose () {
    this.contentEl_ = null
    super.dispose()
  }
}

AdLabel.prototype.labelText_ = 'Advertisement'
AdLabel.prototype.controlText = 'Advertisement'

Component.registerComponent('AdLabel', AdLabel)
