import videojs from 'video.js'

const Component = videojs.getComponent('Component')

class AdPlayProgressBar extends Component {
  createEl () {
    return super.createEl('div', {
      className: this.buildCSSClass()
    })
  }

  buildCSSClass () {
    return 'vjs-play-progress vjs-slider-bar'
  }
}

Component.registerComponent('AdPlayProgressBar', AdPlayProgressBar)
