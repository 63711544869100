import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const Button = videojs.getComponent('Button')

class AdPlayToggle extends Button {
  constructor (player, options = {}) {
    super(player, options)
  }

  buildCSSClass () {
    return `vjs-ad-play-control vjs-play-control ${super.buildCSSClass()}`
  }

  handleClick (event) {
    if (this.controller !== undefined) {
      this.controller.onAdPlayPauseClick()
    }
  }

  handlePlay (event) {
    this.removeClass('vjs-ended')
    this.removeClass('vjs-paused')
    this.addClass('vjs-playing')
    this.controlText('Pause')
  }

  handlePause (event) {
    this.removeClass('vjs-playing')
    this.addClass('vjs-paused')
    this.controlText('Play')
  }

  get controller () {
    return this.player_.ima.controller || undefined
  }
}

Component.registerComponent('AdPlayToggle', AdPlayToggle)
