import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const PlayProgressBar = videojs.getComponent('PlayProgressBar')

/**
 * Custom Play progress bar component
 *
 * @extends PlayProgressBar
 * @class CustomPlayProgressBar
 */
class CustomPlayProgressBar extends PlayProgressBar {}

PlayProgressBar.prototype.options_.children.push('timeTooltip')

Component.registerComponent('PlayProgressBar', CustomPlayProgressBar)
export default CustomPlayProgressBar
