import videojs from 'video.js'
import './BaseControlBar.js'

const Component = videojs.getComponent('Component')
const BaseControlBar = videojs.getComponent('BaseControlBar')

/**
 * Advanced ControlBar component
 *
 * @extends BaseControlBar
 * @class AdvancedControlBar
 */
class AdvancedControlBar extends BaseControlBar {
  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-advanced-control-bar'
  }
}

/**
 * Default options for `BasicControlBar`
 *
 * @type {Object}
 * @private
 */
AdvancedControlBar.prototype.options_ = {
  children: [
    'playToggle',
    (videojs.browser.IS_IPAD || videojs.browser.IS_IPHONE || videojs.browser.IS_ANDROID) ? 'muteToggle' : 'volumePanel',
    'currentTimeDisplay',
    'timeDivider',
    'durationDisplay',
    'progressControl',
    'liveDisplay',
    'seekToLive',
    'liveEdgeTimeDisplay'
  ],
  volumePanel: (videojs.browser.IS_IPAD || videojs.browser.IS_IPHONE || videojs.browser.IS_ANDROID)
    ? false
    : {
      inline: false,
      volumeControl: {
        vertical: true
      }
    }
}

Component.registerComponent('AdvancedControlBar', AdvancedControlBar)
export default AdvancedControlBar
