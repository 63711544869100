import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const DurationDisplay = videojs.getComponent('DurationDisplay')

class BigDurationDisplay extends DurationDisplay {
  constructor (player, options) {
    super(player, options)

    this.hide()
    player.on('durationchange', this.handleDurationChange.bind(this))

    if (player.duration()) {
      this.handleDurationChange()
    }
  }

  handleDurationChange () {
    if (this.player_.duration() && this.player_.duration() !== Infinity) {
      this.show()
    } else {
      this.hide()
    }
  }

  buildCSSClass () {
    return 'agnoplayer-duration-display'
  }
}

Component.registerComponent('BigDurationDisplay', BigDurationDisplay)
export default BigDurationDisplay
