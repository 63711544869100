import videojs from 'video.js'
import { secondsToDuration } from '../utils/secondsToDuration'

const Dom = videojs.dom

const Component = videojs.getComponent('Component')
const DurationDisplay = videojs.getComponent('DurationDisplay')

class CustomDurationDisplay extends DurationDisplay {
  constructor (...args) {
    super(...args)
    this.duration = 0
    this.player_.on('loadedmetadata', () => { this.updateContent() })
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    this.duration = this.options_?.playerOptions?.duration || 0
    this.durationElement = Dom.createEl('span', {
      className: 'vjs-duration-display',
      textContent: secondsToDuration(this.duration)
    })
    const el = super.createEl()
    Dom.emptyEl(el)
    el.appendChild(this.durationElement)
    return el
  }

  updateContent () {
    let newDuration = Math.floor(this.player_.duration())
    if (newDuration === this.duration) {
      return
    }
    if (isNaN(newDuration)) {
      newDuration = 0
    }

    this.durationElement.textContent = secondsToDuration(newDuration)
    this.duration = newDuration
  }
}

Component.registerComponent('DurationDisplay', CustomDurationDisplay)
export default CustomDurationDisplay
