import videojs from 'video.js'
import * as Fn from '../utils/fn.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * Related Content control
 *
 * @extends Component
 * @class ThumbnailPreviewContainer
 */
class ThumbnailPreviewContainer extends Component {
  constructor (player, options) {
    super(player, options)

    this.lastHover = null
    this.update = Fn.throttle(Fn.bind(this, this.update), Fn.UPDATE_REFRESH_INTERVAL)
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-thumbnail-container'
  }

  getScrollOffset () {
    if (window.pageXOffset) {
      return {
        x: window.pageXOffset,
        y: window.pageYOffset
      }
    }
    return {
      x: document.documentElement.scrollLeft,
      y: document.documentElement.scrollTop
    }
  }

  getComputedStyle (el, pseudo) {
    return function (prop) {
      if (window.getComputedStyle) {
        return window.getComputedStyle(el, pseudo)[prop]
      } else {
        return el.currentStyle[prop]
      }
    }
  }

  offsetParent (el) {
    if (el.nodeName !== 'HTML' && this.getComputedStyle(el)('position') === 'static') {
      return this.offsetParent(el.offsetParent)
    }
    return el
  }

  update (seekBarRect, seekBarPoint, hoveredTime) {
    if (hoveredTime !== this.lastHover) {
      this.lastHover = hoveredTime

      const thumbnailPreviewPlugin = this.player().thumbnailPreview()
      const spriteImageUrl = thumbnailPreviewPlugin.getSpriteImageUrl()
      const {
        width: tileWidth,
        height: tileHeight
      } = thumbnailPreviewPlugin.getSpriteTileMetadata()
      const closestTile = thumbnailPreviewPlugin.getClosestTile(hoveredTime)
      const container = this.el()

      if (closestTile) {
        container.style.backgroundPositionX = `-${closestTile.x}px`
        container.style.backgroundPositionY = `-${closestTile.y}px`
        container.style.backgroundImage = `url(${spriteImageUrl})`
        container.style.width = `${tileWidth}px`
        container.style.height = `${tileHeight}px`

        const containerRect = Dom.getBoundingClientRect(container)
        const playerRect = Dom.getBoundingClientRect(this.player().el())
        const seekBarPointPx = seekBarRect.width * seekBarPoint
        // const halfWidth = tileWidth / 2 // TODO Is this needed?

        // do nothing if either rect isn't available
        // for example, if the player isn't in the DOM for testing
        if (!playerRect || !containerRect) {
          return
        }

        // This is the space left of the `seekBarPoint` available within the bounds
        // of the player. We calculate any gap between the left edge of the player
        // and the left edge of the `SeekBar` and add the number of pixels in the
        // `SeekBar` before hitting the `seekBarPoint`
        const spaceLeftOfPoint = (seekBarRect.left - playerRect.left) + seekBarPointPx

        // This is the space right of the `seekBarPoint` available within the bounds
        // of the player. We calculate the number of pixels from the `seekBarPoint`
        // to the right edge of the `SeekBar` and add to that any gap between the
        // right edge of the `SeekBar` and the player.
        const spaceRightOfPoint = (seekBarRect.width - seekBarPointPx) + (playerRect.right - seekBarRect.right)

        // This is the number of pixels by which the tooltip will need to be pulled
        // further to the right to center it over the `seekBarPoint`.
        let right = containerRect.width / 2

        // Adjust the `pullTooltipBy` distance to the left or right depending on
        // the results of the space calculations above.
        if (spaceLeftOfPoint < right) {
          right += right - spaceLeftOfPoint
        } else if (spaceRightOfPoint < right) {
          right = spaceRightOfPoint
        }

        // Due to the imprecision of decimal/ratio based calculations and varying
        // rounding behaviors, there are cases where the spacing adjustment is off
        // by a pixel or two. This adds insurance to these calculations.
        if (right < 0) {
          right = 0
        } else if (right > containerRect.width) {
          right = containerRect.width
        }

        container.style.right = (right > 0 ? '-' : '') + right + 'px'
      }
    }
  }
}

Component.registerComponent('ThumbnailPreviewContainer', ThumbnailPreviewContainer)
export default ThumbnailPreviewContainer
