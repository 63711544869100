import videojs from 'video.js'

/* eslint quote-props: ["error", "as-needed", { "keywords": true, "unnecessary": false }] */
videojs.addLanguage('de', {
  'Audio Player': 'Musikplayer',
  'Video Player': 'Videoplayer',
  'Play': 'Abspielen',
  'Stop': 'Stoppen',
  'Next': 'Nächste',
  'Pause': 'Pause',
  'Replay': 'Wiederholung',
  'Current Time': 'Aktuelle Uhrzeit',
  'Duration': 'Dauer',
  'Remaining Time': 'Verbleibende Zeit',
  'Stream Type': 'Stream-Typ',
  'LIVE': 'LIVE',
  'Loaded': 'Geladen',
  'Progress': 'Fortschritt',
  'Progress Bar': 'Fortschrittsanzeige',
  'progress bar timing: currentTime={1} duration={2}': 'Timing des Fortschrittsbalkens: currentTime={1} Dauer={2}',
  'Fullscreen': 'Vollbildschirm',
  'Non-Fullscreen': 'Nicht-Vollbild',
  'Mute': 'Stumm',
  'Unmute': 'Stummschaltung aufheben',
  'Playback Rate': 'Wiedergabegeschwindigkeit',
  'Subtitles': 'Untertitel',
  'subtitles off': 'Untertitel aus',
  'Captions': 'Bildunterschriften',
  'captions off': 'Bildunterschriften aus',
  'Chapters': 'Kapitel',
  'Descriptions': 'Beschreibungen',
  'descriptions off': 'Beschreibungen aus',
  'Audio Track': 'Audiospur',
  'Volume Level': 'Lautstärkepegel',
  'You aborted the media playback': 'Sie haben die Medienwiedergabe abgebrochen',
  'A network error caused the media download to fail part-way.': 'Ein Netzwerkfehler hat dazu geführt, dass der Mediendownload teilweise fehlgeschlagen ist.',
  'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Das Medium konnte nicht geladen werden, entweder weil der Server oder das Netzwerk ausgefallen sind oder weil das Format nicht unterstützt wird.',
  'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'Die Medienwiedergabe wurde aufgrund eines Korruptionsproblems abgebrochen oder weil die verwendeten Medienfunktionen von Ihrem Browser nicht unterstützt wurden.',
  'No compatible source was found for this media.': 'Für dieses Medium wurde keine kompatible Quelle gefunden.',
  'The media is encrypted and we do not have the keys to decrypt it.': 'Die Medien sind verschlüsselt und wir haben nicht die Schlüssel, um sie zu entschlüsseln.',
  'Play Video': 'Video abspielen',
  'Back': 'Zurück',
  'Close': 'Schließen',
  'Close Modal Dialog': 'Modalen Dialog schließen',
  'Modal Window': 'Modales Fenster',
  'This is a modal window': 'Dies ist ein modales Fenster',
  'This modal can be closed by pressing the Escape key or activating the close button.': 'Dieses Modal kann durch Drücken der Escape-Taste oder durch Aktivieren der Schließen-Schaltfläche geschlossen werden.',
  ', opens captions settings dialog': ', öffnet den Dialog für die Untertiteleinstellungen',
  ', opens subtitles settings dialog': ', öffnet den Dialog mit den Untertiteleinstellungen',
  ', opens descriptions settings dialog': ', öffnet den Einstellungsdialog für Beschreibungen',
  ', selected': ', ausgewählt',
  'captions settings': 'Untertiteleinstellungen',
  'subtitles settings': 'Untertiteleinstellungen',
  'descriptions settings': 'Beschreibungen Einstellungen',
  'Text': 'Text',
  'White': 'Weiß',
  'Black': 'Schwarz',
  'Red': 'rot',
  'Green': 'Grün',
  'Blue': 'Blau',
  'Yellow': 'Gelb',
  'Magenta': 'Magenta',
  'Cyan': 'Cyan',
  'Background': 'Hintergrund',
  'Window': 'Fenster',
  'Transparent': 'Transparent',
  'Semi-Transparent': 'Halbtransparent',
  'Opaque': 'Undurchsichtig',
  'Font Size': 'Schriftgröße',
  'Text Edge Style': 'Textkantenstil',
  'None': 'Keiner',
  'Raised': 'Erzogen',
  'Depressed': 'Deprimiert',
  'Uniform': 'Uniform',
  'Dropshadow': 'Schlagschatten',
  'Font Family': 'Schriftfamilie',
  'Proportional Sans-Serif': 'Proportionale Sans-Serif',
  'Monospace Sans-Serif': 'Monospace Sans-Serif',
  'Proportional Serif': 'Proportionale Serifen',
  'Monospace Serif': 'Monospace-Serifen',
  'Casual': 'Beiläufig',
  'Script': 'Skript',
  'Small Caps': 'Kapitälchen',
  'Reset': 'Zurücksetzen',
  'restore all settings to the default values': 'alle Einstellungen auf die Standardwerte zurücksetzen',
  'Done': 'Fertig',
  'Caption Settings Dialog': 'Dialogfeld "Beschriftungseinstellungen"',
  'Beginning of dialog window. Escape will cancel and close the window.': 'Beginn des Dialogfensters. Escape bricht ab und schließt das Fenster.',
  'End of dialog window.': 'Ende des Dialogfensters.',
  'Watch this video': 'Schau dieses Video an',
  'Listen to this audio': 'Hören Sie sich dieses Audio an',
  'Also watch': 'Auch gucken',
  'Advertisement': 'Werbung',
  'Related': 'Verwandt',
  'Social Share': 'Soziales Teilen',
  'Direct link': 'Direkte Verbindung',
  'Open Chromecast menu': 'Chromecast-Menü öffnen',
  'Start AirPlay': 'AirPlay starten',
  'Normal': 'Normal',
  'Automatic': 'Automatisch',
  'Settings': 'Einstellungen',
  'Quality': 'Qualität',
  'Speed': 'Geschwindigkeit',
  'Up Next': 'Als nächstes',
  'Copy to clipboard': 'In die Zwischenablage kopieren',
  'Copied': 'Kopiert',
  'Your video will be interrupted for a short moment in': 'Dein Video wird für einen kurzen Moment unterbrochen',
  'seconds': 'Sekunden',
  'Your video will start soon': 'Das Video startet in Kürze',
  'Your audio will start soon': 'Ihr Audio wird bald beginnen',
  'More': 'Mehr',
  'Share': 'Teilen',
  'Share this video': 'Teile dieses Video',
  'Share this podcast': 'Teile diesen Podcast'
})
