import videojs from 'video.js'

const MenuItem = videojs.getComponent('MenuItem')

/**
 * Player Settings control
 *
 * @extends MenuItem
 * @class SettingsMenuItem
 */
class SettingsMenuItem extends MenuItem {
  constructor (player, options) {
    super(player, options)
    this.removeChild(this.controlTextEl_)
  }

  createEl (tag = 'li', props = {}, attributes = {}) {
    // The control is textual, not just an icon
    this.nonIconControl = true

    props = Object.assign({
      className: this.buildCSSClass(),
      tabIndex: -1
    }, props)

    return super.createEl(tag, props, attributes)
  }

  attachInnerHTML (content) {
    this.el().innerHTML = content
  }

  addCallbackValue (value) {
    this.callbackValue = value
  }

  buildCSSClass () {
    return 'vjs-menu-item ' + this.options_.customClass
  }

  handleClick (event) {
    event.stopImmediatePropagation()
    if (this.options_.clickCallback) {
      this.options_.clickCallback(this.callbackValue)
    }
  }
}

MenuItem.registerComponent('SettingsMenuItem', SettingsMenuItem)
export default SettingsMenuItem
