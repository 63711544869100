import videojs from 'video.js'

const ClickableComponent = videojs.getComponent('ClickableComponent')
const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * Indicator when video is muted for autoplay
 *
 * @extends ClickableComponent
 * @class AutoplayMuteButton
 */
class AutoplayMuteButton extends ClickableComponent {
  constructor (player, options) {
    super(player, options)

    this.controlText('Unmute')
    this.on(player, ['loadstart', 'volumechange'], this.update)
    this.on(player, 'loadedmetadata', this.setMuted)
  }

  createEl (tag, props = {}, attributes = {}) {
    const el = super.createEl('div')
    const icon = Dom.createEl('span', {
      className: 'mute-icon'
    })
    const text = Dom.createEl('span', {
      className: 'mute-text',
      textContent: this.localize('Unmute')
    })
    el.appendChild(icon)
    el.appendChild(text)

    return el
  }

  buildCSSClass () {
    return 'agnoplayer-autoplay-mute-toggle'
  }

  handleClick (event) {
    event.stopPropagation()
    this.player_.muted(false)
  }

  setMuted = () => {
    if (this.player_.autoplay() && this.player_.muted()) {
      this.player_.addClass('agnoplayer-autoplay-muted')
    }
  }

  update (event) {
    if (!this.player_.muted()) {
      this.player_.removeClass('agnoplayer-autoplay-muted')
    }
  }

  dispose () {
    if (this.isDisposed_) {
      return
    }
    this.isDisposed_ = true
    this.off(this.player_, ['loadstart', 'volumechange'], this.update)
    this.off(this.player_, 'loadedmetadata', this.setMuted)
    super.dispose()
  }
}

Component.registerComponent('AutoplayMuteButton', AutoplayMuteButton)
export default AutoplayMuteButton
