import videojs from 'video.js'

const Plugin = videojs.getPlugin('plugin')

const SECONDS_PER_SECOND = 1
const SECONDS_PER_MINUTE = 60
const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE
const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR

class JsonLD extends Plugin {
  constructor (player, options) {
    super(player, options)

    this.options_ = options

    if (this.options_) {
      this.player.on('videometadataupdated', this.onMetadataUpdate.bind(this))
    }
  }

  onMetadataUpdate () {
    this.updateOptions()
    this.updateEl()
  }

  updateOptions () {
    const metadata = this.player.videoMetadata()
    const language = this.player.language_
    const url = this.options_.url || window.location.href

    this.options_ = videojs.obj.merge({
      metadata,
      language,
      url
    }, this.options_)
  }

  createJSONVideoObject = () => {
    const {
      url,
      metadata,
      playerId,
      embedUrl,
      language
    } = this.options_

    if (metadata) {
      const {
        dateCreated,
        description,
        title,
        keywords,
        duration,
        poster,
        datePublishStart,
        live
      } = metadata

      const videoObjectId = `${url.split('#')[0].replace(/\/$/, '')}/#${playerId}`

      const jsonVideoObject = {
        '@context': 'http://schema.org/',
        '@type': 'VideoObject',
        '@id': videoObjectId,
        name: title,
        description: description || '',
        duration: this.toISO8601(duration),
        inLanguage: language,
        embedUrl,
        keywords: keywords ? keywords.join() : '',
        uploadDate: datePublishStart || dateCreated,
        thumbnailUrl: poster
      }

      if (live) {
        jsonVideoObject.publication = {
          '@type': 'BroadcastEvent',
          name: title,
          isLiveBroadcast: live
        }
      }

      return jsonVideoObject
    }
  }

  createEl () {
    const data = this.createJSONVideoObject()

    if (data) {
      this.scriptNode = document.createElement('script')
      this.scriptNode.type = 'application/ld+json'
      this.scriptNode.innerHTML = JSON.stringify(data)
      this.scriptNode.id = `videoObject-${this.options_.playerId}`
      document.querySelector('head').appendChild(this.scriptNode)
    }
  }

  updateEl () {
    if (this.scriptNode) {
      this.scriptNode.innerHTML = JSON.stringify(this.createJSONVideoObject())
    } else {
      this.createEl()
    }
  }

  dispose () {
    if (this.scriptNode) {
      document.querySelector('head').removeChild(this.scriptNode)
    }
    super.dispose()
  }

  toISO8601 = (sec) => {
    const designations = [
      ['D', SECONDS_PER_DAY],
      ['H', SECONDS_PER_HOUR],
      ['M', SECONDS_PER_MINUTE],
      ['S', SECONDS_PER_SECOND]
    ]

    let duration = 'PT'
    let remainder = sec

    designations.forEach(([sign, seconds]) => {
      const value = Math.floor(remainder / seconds)

      remainder %= seconds

      if (value) {
        duration += `${value}${sign}`
      }
    })

    if (duration === 'PT') {
      duration = 'PT0S'
    }

    return duration
  }
}

videojs.registerPlugin('jsonLD', JsonLD)
