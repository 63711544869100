import videojs from 'video.js'

const MenuItem = videojs.getComponent('MenuItem')
const Component = videojs.getComponent('Component')

/**
 * @extends MenuItem
 * @class SettingsMenuItem
 */
class SettingsContainerMenuItem extends MenuItem {
  constructor (player, options) {
    super(player, options)

    this.containerButton_ = options.containerButton
    this.menuType_ = options.menuType
  }

  handleClick () {
    super.handleClick()
    this.containerButton_.selectMenuType(this.menuType_)
  }
}

Component.registerComponent('SettingsContainerMenuItem', SettingsContainerMenuItem)
export default SettingsContainerMenuItem
