import videojs from 'video.js'
import SettingsMenu from './SettingsMenu'
import PlayBackRateSubMenu from './PlayBackRateSubMenu'
import PlayBackQualitySubMenu from './PlayBackQualitySubMenu'
import { menuTypes } from '../utils/menuTypes'
import { MIN_BITRATE_HD } from '../utils/constants'

const MenuButton = videojs.getComponent('MenuButton')
const Dom = videojs.dom

/**
 * Player settings menu control
 * @extends MenuButton
 * @class SettingsButton
 */
class SettingsButton extends MenuButton {
  constructor (player, options) {
    super(player, options)
    this.controlText('Settings')

    // Prevent the popup from showin when the mouse is over the settings button
    this.on(this.menuButton_, 'mouseenter', this.handleMouseLeave)

    player.on('loadstart', () => {
      this.createMenu()
      this.refreshActiveMenu(menuTypes.MAIN)
    })
    player.qualityLevels().on('change', () => { this.handleQualityLevelUpdate() })
  }

  update () {
    /**
     * Track the state of the menu button
     *
     * @type {Boolean}
     * @private
     */
    this.buttonPressed_ = false
    this.menuButton_.el_.setAttribute('aria-expanded', 'false')

    if (this.items && this.items.length <= this.hideThreshold_) {
      this.hide()
    } else {
      this.show()
    }
  }

  refreshActiveMenu (menuType = menuTypes.MAIN) {
    if (this.menu) {
      this.menu.unlockShowing()
    }
    switch (menuType) {
      case menuTypes.MAIN:
        this.menu = this.mainMenu
        break
      case menuTypes.QUALITY:
        this.menu = this.qualityMenu
        break
      case menuTypes.PLAYBACKRATE:
        this.menu = this.playBackRateMenu
        break
    }
    if (this.buttonPressed_) {
      this.menu.lockShowing()
      this.menu.focus()
    }

    // recalculate width and height for container element here so we can add a css transition
    const clientRect = this.menu.contentEl().getBoundingClientRect()
    this.containerEl.style.height = clientRect.height + 'px'
    this.containerEl.style.width = clientRect.width + 'px'

    switch (menuType) {
      case menuTypes.MAIN:
        this.mainMenu.el().style.opacity = 1
        this.qualityMenu.el().style.opacity = 0
        this.playBackRateMenu.el().style.opacity = 0
        break
      case menuTypes.QUALITY:
        this.mainMenu.el().style.opacity = 0
        this.qualityMenu.el().style.opacity = 1
        this.playBackRateMenu.el().style.opacity = 0
        break
      case menuTypes.PLAYBACKRATE:
        this.mainMenu.el().style.opacity = 0
        this.qualityMenu.el().style.opacity = 0
        this.playBackRateMenu.el().style.opacity = 1
        break
    }
  }

  createMenu () {
    this.mainMenu = new SettingsMenu(this.player_, { menuButton: this, playbackRates: this.playbackRates() })
    this.addMenu(this.mainMenu)
    this.qualityMenu = new PlayBackQualitySubMenu(this.player_, { menuButton: this })
    this.addMenu(this.qualityMenu)
    this.playBackRateMenu = new PlayBackRateSubMenu(this.player_, { menuButton: this, playbackRates: this.playbackRates() })
    this.addMenu(this.playBackRateMenu)
  }

  createEl () {
    const el = super.createEl()
    this.containerEl = Dom.createEl('div', { className: 'vjs-settings-container' })
    el.appendChild(this.containerEl)
    return el
  }

  addMenu (menu) {
    Dom.appendContent(this.containerEl, menu.el())
  }

  playbackRates () {
    return this.options_.playbackRates || (this.options_.playerOptions && this.options_.playerOptions.playbackRates)
  }

  buildCSSClass () {
    return `vjs-player-settings-control ${super.buildCSSClass()}`
  }

  unpressButton () {
    super.unpressButton()
    this.refreshActiveMenu()
    this.menuButton_.el_.classList.remove('vjs-settings-menu-opened')
  }

  handleQualityLevelUpdate () {
    const qualityLevels = this.player().qualityLevels()
    if (qualityLevels.length > 0 && qualityLevels[qualityLevels.selectedIndex]) {
      if (qualityLevels[qualityLevels.selectedIndex].bitrate >= MIN_BITRATE_HD) {
        this.addClass('vjs-quality-hd')
      } else {
        this.removeClass('vjs-quality-hd')
      }
    }
  }

  handleClick () {
    super.handleClick()
    this.menuButton_.el_.classList.add('vjs-settings-menu-opened')
  }
}

MenuButton.registerComponent('SettingsButton', SettingsButton)
export default SettingsButton
