import videojs from 'video.js'

const Button = videojs.getComponent('Button')
const Component = videojs.getComponent('Component')

/**
 * Download button
 *
 * @extends Button
 * @class PodcastDownloadButton
 */
class PodcastDownloadButton extends Button {
  constructor (player, options) {
    super(player, options)
    this.controlText('Download')
  }

  createEl (tag, props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'agnoplayer-icon-download vjs-button'
  }

  handleClick () {
    this.player_.trigger('podcastdownload')
    window.open(this.player().src())
  }
}

Component.registerComponent('PodcastDownloadButton', PodcastDownloadButton)
export default PodcastDownloadButton
