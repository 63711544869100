import videojs from 'video.js'
import '../ModalContainer'
import '../ModalSettingsContainer'
import '../ContainerButton'
import './PodcastDownloadButton'
import './PodcastInfoButton'
import './PodcastShareButton'
// import { THEME_SLIM } from '../../vars'

const Component = videojs.getComponent('Component')

/**
 * Podcast Button Container
 *
 * @extends Component
 * @class PodcastButtonContainer
 */
class PodcastButtonContainer extends Component {
  constructor (player, options) {
    options = videojs.obj.merge({
      PodcastShareButton: options.playerOptions.socialButtons !== false,
      PodcastDownloadButton: options.playerOptions.podcastDownloadButton
    }, options)
    super(player, options)
  }

  buildCSSClass () {
    return 'agnoplayer-podcastbuttoncontainer'
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }
}

/**
 * Default options for `PodcastButtonContainer`
 *
 * @type {Object}
 * @private
 */
PodcastButtonContainer.prototype.options_ = {
  children: [
    'PodcastDownloadButton',
    'PodcastShareButton',
    'PodcastInfoButton',
    'volumePanel',
    'muteToggle'
  ]
}

Component.registerComponent('PodcastButtonContainer', PodcastButtonContainer)
export default PodcastButtonContainer
