import videojs from 'video.js'

const Component = videojs.getComponent('Component')

/**
 * Player Brand Logo control
 *
 * @extends Component
 * @class PlayerBrandLogo
 */
class PlayerBrandLogo extends Component {
  constructor (player, options) {
    options = videojs.obj.merge({
      brandLogoPosition: 'top-right'
    }, options)
    super(player, options)

    const brandLogoType = this.options().brandLogoType
    const className = brandLogoType && brandLogoType !== 'default'
      ? `brand-logo-${brandLogoType}`
      : 'brand-logo'
    this.player().addClass(`${className}-${this.options().brandLogoPosition}`)
  }

  createEl (tag, props = {}, attributes = {}) {
    const decodedImage = atob(this.options().brandImage)
    props = Object.assign({
      innerHTML: decodedImage,
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return `vjs-brand-logo ${this.options().brandLogoType} ${this.options().brandLogoPosition}`
  }
}

Component.registerComponent('PlayerBrandLogo', PlayerBrandLogo)
export default PlayerBrandLogo
