import videojs from 'video.js'

const ClickableComponent = videojs.getComponent('ClickableComponent')

/**
 * Clickable Overlay control
 *
 * @extends ClickableComponent
 * @class ClickableOverlay
 */
class ClickableOverlay extends ClickableComponent {
  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-clickable-overlay'
  }

  handleClick (event) {
    window.open(this.options_.linkUrl, '_blank')

    if (!this.player().paused()) {
      this.player().pause()
    }
  }
}

ClickableComponent.registerComponent('ClickableOverlay', ClickableOverlay)
export default ClickableOverlay
