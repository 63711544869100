import videojs from 'video.js'

const Plugin = videojs.getPlugin('plugin')

class AgnoplayData extends Plugin {
  constructor (player, options) {
    super(player)
    this.config = options

    player.ready(this.ready)
  }

  ready = () => {
    const eventBus = window.AGNO.eventBus

    window.AGNOPLAY_DATA_LISTENERS_ADDED = (window.AGNOPLAY_DATA_LISTENERS_ADDED || 0) + 1
    eventBus.on(`agnoplay:event:${this.config.playerId}`, this.sendToPlugin)
  }

  sendToPlugin = (event) => {
    const agnoplayData = window.AgnoplayData

    agnoplayData.send({
      options: this.config,
      event,
      metadata: {
        playerId: this.config.playerId,
        date: new Date().getTime(),
        url: window.location.href,
        listenersAdded: window.AGNOPLAY_DATA_LISTENERS_ADDED,
        listenersRemoved: window.AGNOPLAY_DATA_LISTENERS_REMOVED
      }
    })
  }

  dispose () {
    const eventBus = window.AGNO.eventBus

    eventBus.off(`agnoplay:event:${this.config.playerId}`, this.sendToPlugin)
    window.AGNOPLAY_DATA_LISTENERS_REMOVED = (window.AGNOPLAY_DATA_LISTENERS_REMOVED || 0) + 1
  }
}

videojs.registerPlugin('agnoplayData', AgnoplayData)
