import videojs from 'video.js'
import SettingsMenuItem from './SettingsMenuItem'
import { MIN_BITRATE_HD } from '../utils/constants'

const Menu = videojs.getComponent('Menu')
/**
 * Player settings quality menu content quality container
 *
 * @extends Menu
 * @class PlayBackQualitySubMenu
 */
class PlayBackQualitySubMenu extends Menu {
  constructor (player, options) {
    super(player, options)
    this.qualityLevelListChanged()
    this.addClass('vjs-settings-menu')
    this.addClass('vjs-settings-submenu')
    player.qualityLevels().on(['addqualitylevel', 'removequalitylevel', 'change'], () => { this.handleQualityLevelsChange() })
  }

  handleQualityLevelsChange () {
    this.setTimeout(this.qualityLevelListChanged, 400)
  }

  handleBlur (event) {
    if (this.hasClass('vjs-lock-showing')) {
      const relatedTarget = event.relatedTarget || document.activeElement

      // Close menu popup when a user clicks outside the menu
      if (!relatedTarget.classList.contains('vjs-menu-item')) {
        const btn = this.menuButton_

        if (btn && btn.buttonPressed_ && relatedTarget !== btn.el().firstChild) {
          btn.unpressButton()
        }
      }
    }
  }

  qualityLevelListChanged () {
    this.removeChildren()
    const headerItem = new SettingsMenuItem(this.player_, { customClass: 'vjs-header-menu-item vjs-align-center', clickCallback: this.clickCallback })
    headerItem.attachInnerHTML(`<span class="vjs-icon-placeholder vjs-menu-pull-left"></span>${this.player().localize('Quality')}`)
    this.addItem(headerItem)

    const selectedItems = this.getEnabledItems()
    const qualityLevels = this.player().qualityLevels()
    if (this.player().currentSources().filter(item => item.type !== 'video/mp4').length > 0) {
      // only add automatic option if adaptive playlists are available.
      const automaticQualityLevelItem = new SettingsMenuItem(this.player_, { customClass: `vjs-align-center ${selectedItems.length === qualityLevels.length ? 'vjs-selected' : ''}`, clickCallback: this.clickCallback })
      automaticQualityLevelItem.addCallbackValue('all')
      automaticQualityLevelItem.attachInnerHTML(`<span>${this.player().localize('Automatic')}</span>`)
      this.addItem(automaticQualityLevelItem)
    }

    const sortedQualityLevels = Array.from(qualityLevels).sort((a, b) => {
      if (b.height === a.height) {
        return b.bitrate - a.bitrate
      }
      return b.height - a.height
    })
    for (let i = 0; i < sortedQualityLevels.length; i++) {
      const qualityLevelItem = new SettingsMenuItem(this.player_, {
        customClass: `vjs-align-center ${selectedItems.length !== sortedQualityLevels.length && selectedItems[0].height === sortedQualityLevels[i].height && selectedItems[0].bitrate === sortedQualityLevels[i].bitrate ? 'vjs-selected' : ''}`,
        clickCallback: this.clickCallback
      })
      let label
      const qualityLevel = sortedQualityLevels[i]
      if (qualityLevel.label && qualityLevel.label.match(/^[0-9]+p$/) !== null) {
        label = qualityLevel.label
      } else {
        label = qualityLevel.height + 'p'
      }
      qualityLevelItem.addCallbackValue(qualityLevel)
      qualityLevelItem.attachInnerHTML(
        `<span>${label}</span>
          ${qualityLevel.bitrate >= MIN_BITRATE_HD ? '<span class="vjs-label-hd vjs-menu-highlight">HD</span>' : ''}
          <span class="vjs-label-bitrate">${this.bitrateLabel(qualityLevel.bitrate)}</span>`
      )
      this.addItem(qualityLevelItem)
    }
  }

  getEnabledItems () {
    const enabledItems = []
    const qualityLevels = this.player().qualityLevels()
    for (let i = 0; i < qualityLevels.length; i++) {
      if (qualityLevels[i].enabled) {
        enabledItems.push(qualityLevels[i])
      }
    }
    return enabledItems
  }

  bitrateLabel (bitrate) {
    if (bitrate === undefined) {
      return ''
    }
    let i = -1
    const byteUnits = [' kbps', ' Mbps', ' Gbps']
    do {
      bitrate = bitrate / 1024
      i++
    } while (bitrate > 1024)

    return Math.max(bitrate, 0.1).toFixed(1) + byteUnits[i]
  }

  clickCallback = (value) => {
    if (value) {
      const qualityLevels = this.player().qualityLevels()
      for (let i = 0; i < qualityLevels.length; i++) {
        const selectedQuality = (qualityLevels[i].bitrate === value.bitrate && qualityLevels[i].height === value.height)
        qualityLevels[i].enabled = (value === 'all') ? true : selectedQuality
        if (selectedQuality) {
          qualityLevels.selectedIndex_ = i
        }
      }
      this.player().qualityLevels().trigger({ type: 'change' })
    }
    this.menuButton_.refreshActiveMenu()
  }

  removeChildren () {
    if (this.children()) {
      for (let i = this.children().length - 1; i >= 0; i--) {
        this.removeChild(this.children()[i])
      }
    }
  }
}

Menu.registerComponent('PlayBackQualitySubMenu', PlayBackQualitySubMenu)
export default PlayBackQualitySubMenu
