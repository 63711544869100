import videojs from 'video.js'
import '../components/PlayerDragListenerContent'

const Plugin = videojs.getPlugin('plugin')

class PictureInPicture extends Plugin {
  constructor (player, options) {
    super(player, options)

    const posterImageIndex = player.children().indexOf(player.posterImage)
    this.closed = false
    player.addChild('PlayerDragListenerContent', {}, posterImageIndex)
    player.on('pipclosed', this.closePlayer.bind(this))
    this.player.on('play', this.pauseDisposeOther)
  }

  handleStateChanged (e) {
    if (e.changes && e.changes.customClass) {
      if (e.changes.customClass.from) {
        this.player.removeClass(e.changes.customClass.from)
      }
      if (e.changes.customClass.to && e.changes.customClass.to !== 'off') {
        this.addScrollListener()
        this.player.addClass(e.changes.customClass.to)
      } else {
        this.dispose()
      }
    }
  }

  pauseDisposeOther () {
    const player = this.player()
    Object.values(window.AGNO.Players.list).forEach((pl) => {
      if (pl.agnoInstance && player.options_.playerId !== pl.id) {
        if (pl.agnoInstance.isInPictureInPicture_ === true) {
          if (!pl.agnoInstance.isPausedAdOrVideo()) {
            pl.pause()
          }
          pl.agnoInstance.exitPictureInPicture()
        }
      }
    })
  }

  dispose () {
    window.removeEventListener('scroll', this.scrollListener, true)
    this.player.exitPictureInPicture()
    super.dispose()
  }

  addScrollListener = () => {
    window.addEventListener('scroll', this.scrollListener, true)
  }

  otherPipOpened = () => {
    let opened = false
    Object.values(window.AGNO.Players.list).forEach((pl) => {
      if (pl.agnoInstance && this.player.options_.playerId !== pl.id) {
        if (pl.agnoInstance.isInPictureInPicture_ === true) {
          opened = true
        }
      }
    })
    return opened
  }

  scrollListener = () => {
    const isOutOfViewport = this.isOutOfViewport()
    if (!isOutOfViewport && this.closed === true) {
      this.closed = false
    }
    if (isOutOfViewport && !this.player.isInPictureInPicture() && this.closed === false) {
      if (!this.otherPipOpened() && !this.player.isPausedAdOrVideo()) {
        this.player.requestPictureInPicture()
      }
    } else if (!isOutOfViewport && this.player.isInPictureInPicture()) {
      this.player.exitPictureInPicture()
    }
  }

  closePlayer = () => {
    this.closed = true
    this.player.exitPictureInPicture()
  }

  isOutOfViewport () {
    return this.player.el().parentElement.getBoundingClientRect().bottom < 20
  }
}
videojs.registerPlugin('pictureInPicture', PictureInPicture)
