import videojs from 'video.js'
import * as Fn from '../../utils/fn.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

const AD_ANNOUNCEMENT_OFFSET = 10

class AdAnnouncement extends Component {
  constructor (player, options) {
    if (!player.hasPlugin('adsManager')) {
      throw new Error('Required plugin missing: AdsManager')
    }
    super(player, options)
    this.cuePoints = []
    this.update()
    player.on('cuepointchange', this.onCuePointChange.bind(this))
  }

  onCuePointChange () {
    const cuePoints = this.player().adsManager().getCuePoints()
    this.cuePoints = cuePoints.filter(cuePoint => cuePoint > 0)
    if (this.cuePoints.length > 0) {
      this.updateInterval = null
      this.on(this.player_, ['playing'], this.enableInterval_)
      this.on(this.player_, ['ended', 'pause', 'waiting'], this.disableInterval_)
      this.update = Fn.throttle(Fn.bind(this, this.update), Fn.UPDATE_REFRESH_INTERVAL)
    }
  }

  enableInterval_ () {
    if (this.updateInterval) {
      return
    }
    this.updateInterval = this.setInterval(this.update, Fn.UPDATE_REFRESH_INTERVAL)
  }

  disableInterval_ () {
    if (!this.updateInterval) {
      return
    }
    this.clearInterval(this.updateInterval)
    this.updateInterval = null
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-ads-countdown'
  }

  update () {
    const time = (this.player_.scrubbing())
      ? this.player_.getCache().currentTime
      : this.player_.currentTime()
    this.cuePoints = this.cuePoints.filter(cuePoint => cuePoint > time)
    // check if cuePoint is within X seconds then addClass to show and update DOM
    for (const cuePoint of this.cuePoints) {
      if ((cuePoint - time) < AD_ANNOUNCEMENT_OFFSET) {
        this.show()
        Dom.textContent(this.el(), `${this.player().localize('Jouw video wordt even onderbroken over')} ${Math.round(cuePoint - time)} ${this.player().localize('seconds')}`)
        return
      }
    }
    this.hide()
  }

  dispose () {
    this.disableInterval_()
  }
}

Component.registerComponent('AdAnnouncement', AdAnnouncement)
export default AdAnnouncement
