import videojs from 'video.js'

const Button = videojs.getComponent('Button')
const Component = videojs.getComponent('Component')

/**
 * Related Content control
 *
 * @extends Button
 * @class MicroCloseButton
 */
class MicroCloseButton extends Button {
  constructor (player, options) {
    super(player, options)
    this.playerId = options.playerId
    this.controlText('Close')
    if (options.buttonClass) {
      this.addClass(options.buttonClass)
    }
  }

  createEl (tag, props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'agnoplayer-micro-close-button'
  }

  handleClick (event) {
    event.stopPropagation()
    this.player_.trigger('closedbuttonclick')

    if (window.AGNO) {
      window.AGNO.disposePlayer(this.playerId)
    }
  }
}

Component.registerComponent('MicroCloseButton', MicroCloseButton)
export default MicroCloseButton
