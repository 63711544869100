import videojs from 'video.js'

const Plugin = videojs.getPlugin('plugin')

class Recommendations extends Plugin {
  constructor (player, options) {
    super(player)
    this.player = player
    this.options_ = options
  }

  updateRecommendations (related) {
    const relatedItems = related || []
    this.player.options_.related = relatedItems

    const playerRelatedItemsContent = this.player
      .getChild('playerRelatedItemsContainer')
      .getChild('playerRelatedItemsContent')
    playerRelatedItemsContent.options_.related = relatedItems
    playerRelatedItemsContent.updateContent(relatedItems)

    if (this.options_.upnext) {
      const playerNext = this.player.getChild('playerNext')
      playerNext.options_.related = relatedItems
      playerNext.updateContent(relatedItems)
    }

    const playerRelatedHeaderButton = this.player
      .getChild('playerHeader')
      .getChild('playerRelated')
    const playerRelatedCBCButton = this.player
      .getChild('controlButtonContainer')
      .getChild('playerRelated')

    if (relatedItems?.length) {
      playerRelatedHeaderButton.show()
      playerRelatedCBCButton.show()
    } else {
      playerRelatedHeaderButton.hide()
      playerRelatedCBCButton.hide()
    }
  }
}

videojs.registerPlugin('recommendations', Recommendations)
