export function assign (target, ...sources) {
  if (Object.assign) {
    return Object.assign(target, ...sources)
  }

  sources.forEach(source => {
    if (!source) {
      return
    }

    each(source, (value, key) => {
      target[key] = value
    })
  })

  return target
}

export function each (object, fn) {
  keys(object).forEach(key => fn(object[key], key))
}

const keys = function (object) {
  return isObject(object) ? Object.keys(object) : []
}

export function isObject (value) {
  return !!value && typeof value === 'object'
}
