import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const MouseTimeDisplay = videojs.getComponent('MouseTimeDisplay')

/**
 * Custom BigPlay Button control
 *
 * @extends BigPlayButton
 * @class CustomBigPlayButton
 */
class CustomMouseTimeDisplay extends MouseTimeDisplay {
  update (seekBarRect, seekBarPoint) {
    super.update(seekBarRect, seekBarPoint)

    if (this.getChild('ThumbnailPreviewContainer')) {
      const time = seekBarPoint * this.player_.duration()
      this.getChild('ThumbnailPreviewContainer').update(seekBarRect, seekBarPoint, time)
    }
  }
}

Component.registerComponent('MouseTimeDisplay', CustomMouseTimeDisplay)
export default CustomMouseTimeDisplay
