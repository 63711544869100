import videojs from 'video.js'
import '../components/PlayerTitle'

const Plugin = videojs.getPlugin('plugin')

class PlayerTitleManager extends Plugin {
  constructor (player, options) {
    options = videojs.obj.merge({
      titlePosition: 'bottom-left'
    }, options)
    super(player, options)
    if (options && options.posterTitle && options.showTitle) {
      player.ready(() => {
        this.attachPlayerTitle(options)
        if (options.showTitleOnStart) {
          player.on('playing', () => { this.onPlaying() })
        }
      })
    }
  }

  onPlaying () {
    this.on(this.player, 'timeupdate', this.onTimeupdate)
  }

  onTimeupdate () {
    const playerTitleEl = this.player.getChild('PlayerTitle')
    if (this.player.currentTime() > 5) {
      if (!playerTitleEl.hasClass('agnoplayer-title-hidden')) {
        playerTitleEl.addClass('agnoplayer-title-hidden')
      }
      this.off(this.player, 'timeupdate', this.onTimeupdate)
    } else {
      if (playerTitleEl.hasClass('agnoplayer-title-hidden')) {
        playerTitleEl.removeClass('agnoplayer-title-hidden')
      }
    }
  }

  attachPlayerTitle (options) {
    const elPlayerTitle = this.player.addChild('PlayerTitle', { ...options })
    if (!options.showTitleOnStart) {
      elPlayerTitle.addClass('agnoplayer-title-hidden')
    }
  }
}

videojs.registerPlugin('playerTitleManager', PlayerTitleManager)
