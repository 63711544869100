import videojs from 'video.js'
import './BaseControlBar.js'

const Component = videojs.getComponent('Component')
const BaseControlBar = videojs.getComponent('BaseControlBar')

/**
 * Basic ControlBar component
 *
 * @extends BaseControlBar
 * @class BasicControlBar
 */
class BasicControlBar extends BaseControlBar {
  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-basic-control-bar'
  }
}

/**
 * Default options for `BasicControlBar`
 *
 * @type {Object}
 * @private
 */
BasicControlBar.prototype.options_ = {
  children: [
    'playToggle',
    (videojs.browser.IS_IPAD || videojs.browser.IS_IPHONE || videojs.browser.IS_ANDROID) ? 'muteToggle' : 'volumePanel',
    'currentTimeDisplay',
    'timeDivider',
    'durationDisplay',
    'progressControl',
    'liveDisplay',
    'seekToLive',
    'liveEdgeTimeDisplay',
    'customControlSpacer',
    'chaptersButton',
    'descriptionsButton',
    'subsCapsButton',
    'audioTrackButton',
    'settingsButton',
    'airPlayButton',
    'chromecastButton',
    'fullscreenToggle'
  ]
}

Component.registerComponent('BasicControlBar', BasicControlBar)
export default BasicControlBar
