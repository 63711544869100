import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const DurationDisplay = videojs.getComponent('DurationDisplay')

class CustomDurationDisplay extends DurationDisplay {
  constructor (player, options = {}) {
    super(player, options)
  }

  createEl () {
    const el = super.createEl()

    this.contentEl_.setAttribute('translate', 'no')

    return el
  }
}

Component.registerComponent('DurationDisplay', CustomDurationDisplay)
