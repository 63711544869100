import videojs from 'video.js'
import './PlayerRelatedItemsContainer'

const Button = videojs.getComponent('Button')

/**
 * Related Content control
 *
 * @extends Button
 * @class PlayerRelated
 */
class PlayerRelated extends Button {
  constructor (player, options) {
    super(player, options)

    const options_ = this.player().options_
    if (!options_.playerRelatedItemsContainer || !options_.related?.length) {
      this.hide()
    }
    this.wasPlaying_ = false

    this.controlText('Related')
    this.on(player, 'loadstart', this.addCloseListener)
  }

  addCloseListener () {
    if (this.player().playerRelatedItemsContainer) {
      this.on(this.player().playerRelatedItemsContainer.closeButton, 'relateditemsclosed', this.resumePlaybackIfNeeded)
    }
  }

  resumePlaybackIfNeeded () {
    if (this.wasPlaying_ && !this.player().ended()) {
      this.player().play()
    }
  }

  buildCSSClass () {
    return 'vjs-player-related top-button vjs-button vjs-control'
  }

  handleClick (event) {
    this.wasPlaying_ = !this.player_.paused()
    this.player_.addClass('vjs-related-open')
    this.player_.trigger('relatedopen')
    if (this.wasPlaying_) {
      this.player_.pause()
    }
  }
}

Button.registerComponent('PlayerRelated', PlayerRelated)
export default PlayerRelated
