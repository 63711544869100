import videojs from 'video.js'
import './PlayerRelatedItemsContainer'

const Button = videojs.getComponent('Button')

/**
 * Social Share control
 *
 * @extends Button
 * @class SocialShare
 */
class SocialShare extends Button {
  constructor (player, options) {
    super(player, options)
    this.wasPlaying_ = false
    this.controlText('Social Share')
    player.addClass('agnoplayer-social-available')
  }

  buildCSSClass () {
    return 'vjs-player-social top-button vjs-button vjs-control'
  }

  createEl (tag = 'button', props = {}, attributes = {}) {
    const el = super.createEl('button', {
      className: this.buildCSSClass(),
      innerHTML: `<span class="vjs-player-social-button-text">${this.localize('Share')}</span>`
    }, props)
    return el
  }

  handleClick (event) {
    this.player_.social().showContainer()
  }
}

Button.registerComponent('SocialShare', SocialShare)
export default SocialShare
