import videojs from 'video.js'
import CustomTextTrackMenuItem from './CustomTextTrackMenuItem'

const Component = videojs.getComponent('Component')
const TextTrackButton = videojs.getComponent('TextTrackButton')
const MenuItem = videojs.getComponent('MenuItem')
const OffTextTrackMenuItem = videojs.getComponent('OffTextTrackMenuItem')

/**
 * The button component for toggling and selecting captions and/or subtitles
 *
 * @extends TextTrackButton
 */
class SubsCapsButton extends TextTrackButton {
  constructor (player, options = {}) {
    super(player, options)

    // Although North America uses "captions" in most cases for
    // "captions and subtitles" other locales use "subtitles"
    this.label_ = 'Subtitles'
    if (['en', 'en-us', 'en-ca', 'fr-ca'].indexOf(this.player_.language_) > -1) {
      this.label_ = 'Captions'
    }
    this.menuButton_.controlText(this.label_)
  }

  /**
   * Builds the default DOM `className`.
   *
   * @return {string}
   *         The DOM `className` for this object.
   */
  buildCSSClass () {
    return `vjs-subs-caps-button ${super.buildCSSClass()}`
  }

  buildWrapperCSSClass () {
    return `vjs-subs-caps-button ${super.buildWrapperCSSClass()}`
  }

  /**
   * Create caption/subtitles menu items
   *
   * @return {CaptionSettingsMenuItem[]}
   *         The array of current menu items.
   */
  createItems () {
    const items = []
    const item = new MenuItem(this.player_, {
      label: this.label_,
      selectable: false,
      multiSelectable: false
    })
    this.on(item, 'click', this.stopPropagation)
    item.addClass('vjs-header-menu-item')
    items.push(item)

    // Label is an override for the [track] off label
    // USed to localise captions/subtitles
    let label

    if (this.label_) {
      label = this.label_ + ' off'
    } // Add an OFF menu item to turn all tracks off

    items.push(new OffTextTrackMenuItem(this.player_, {
      kinds: this.kinds_,
      kind: this.kind_,
      label
    }))
    this.hideThreshold_ += 1
    const tracks = this.player_.textTracks()

    if (!Array.isArray(this.kinds_)) {
      this.kinds_ = [this.kind_]
    }

    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i]

      // only add tracks that are of an appropriate kind and have a label and language
      if (this.kinds_.indexOf(track.kind) > -1 && track.label !== '' && track.language !== '') {
        const item = new CustomTextTrackMenuItem(this.player_, {
          track,
          kinds: this.kinds_,
          kind: this.kind_,
          // MenuItem is selectable
          selectable: true,
          // MenuItem is NOT multiSelectable (i.e. only one can be marked "selected" at a time)
          multiSelectable: false
        })
        item.addClass('vjs-' + track.kind + '-menu-item')
        items.push(item)
      }
    }
    if (items.length > 1) { items[1].addClass('vjs-hidden') }

    // Return an empty array if there is no text track available
    if (items.length === 2) {
      return []
    }
    return items
  }

  stopPropagation (event) {
    event.stopImmediatePropagation()
  }
}

/**
 * `kind`s of TextTrack to look for to associate it with this menu.
 *
 * @type {array}
 * @private
 */
SubsCapsButton.prototype.kinds_ = ['captions', 'subtitles']

/**
 * The text that should display over the `SubsCapsButton`s controls.
 *
 *
 * @type {string}
 * @private
 */
SubsCapsButton.prototype.controlText_ = 'Subtitles'

Component.registerComponent('SubsCapsButton', SubsCapsButton)
export default SubsCapsButton
