import videojs from 'video.js'
import '../components/SocialShareContainer'

const Plugin = videojs.getPlugin('plugin')

class Social extends Plugin {
  constructor (player, options) {
    super(player)
    this.config = options
    this.initSocialContainer()

    this.on(this.container.closeButton, 'socialshareclosed', this.resumePlaybackIfNeeded)
  }

  resumePlaybackIfNeeded () {
    if (this.wasPlaying_) {
      this.player.play()
    }
  }

  initSocialContainer () {
    this.container = this.player.addChild('SocialShareContainer', {
      ...this.config,
      children: { closeButton: { targetClass: 'vjs-social-open', eventName: 'socialshareclosed' } }
    })
  }

  showContainer () {
    this.wasPlaying_ = !this.player.paused()
    this.player.addClass('vjs-social-open')
    if (this.wasPlaying_) {
      this.player.pause()
    }
  }
}
videojs.registerPlugin('social', Social)
