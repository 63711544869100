import videojs from 'video.js'

const Button = videojs.getComponent('Button')
const Component = videojs.getComponent('Component')

/**
 * Share button
 *
 * @extends Button
 * @class PodcastShareButton
 */
class PodcastShareButton extends Button {
  constructor (player, options) {
    super(player, options)
    this.controlText('Share')
  }

  createEl (tag, props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'agnoplayer-icon-share vjs-button'
  }

  handleClick (event) {
    this.player_.social().showContainer()
  }
}

Component.registerComponent('PodcastShareButton', PodcastShareButton)
export default PodcastShareButton
