import videojs from 'video.js'
import '../components/Ads/AdCuepoints'

const Component = videojs.getComponent('Component')
const ProgressControl = videojs.getComponent('ProgressControl')

/**
 * Custom ProgressControl component
 *
 * @extends ControlBar
 * @class ProgressControl
 */
class CustomProgressControl extends ProgressControl {
  constructor (player, options) {
    super(player, options)
    this.addChild('AdCuepoints')
  }
}

Component.registerComponent('ProgressControl', CustomProgressControl)
export default CustomProgressControl
