import videojs from 'video.js'

/* eslint quote-props: ["error", "as-needed", { "keywords": true, "unnecessary": false }] */
videojs.addLanguage('lb', {
  'Audio Player': 'Audio Player',
  'Video Player': 'Video Spiller',
  'Play': 'Spillen',
  'Stop': 'Stop',
  'Next': 'Nächst',
  'Pause': 'Paus',
  'Replay': 'Replay',
  'Current Time': 'Aktuell Zäit',
  'Duration': 'Dauer',
  'Remaining Time': 'Rescht Zäit',
  'Stream Type': 'Stream Typ',
  'LIVE': 'LIVE',
  'Loaded': 'Lueden',
  'Progress': 'Fortschrëtt',
  'Progress Bar': 'Fortschrëtt Bar',
  'progress bar timing: currentTime={1} duration={2}': 'Fortschrëttbar Timing: aktuell Zäit={1} Dauer={2}',
  'Fullscreen': 'Vollbildschierm',
  'Non-Fullscreen': 'Net Vollbildschierm',
  'Mute': 'Mute',
  'Unmute': 'Unmute',
  'Playback Rate': 'Playback Taux',
  'Subtitles': 'Ënnertitelen',
  'subtitles off': 'Ënnertitelen aus',
  'Captions': 'Ënnerschrëften',
  'captions off': 'Ënnerschrëften aus',
  'Chapters': 'Kapitelen',
  'Descriptions': 'Beschreiwunge',
  'descriptions off': 'Beschreiwungen aus',
  'Audio Track': 'Audio Track',
  'Volume Level': 'Volumen Niveau',
  'You aborted the media playback': 'Dir hutt d\'Medien Playback ofgebrach',
  'A network error caused the media download to fail part-way.': 'En Netzfehler huet dozou gefouert datt d\'Media-Download deelweis ausfalen.',
  'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'D\'Medien konnten net geluede ginn, entweder well de Server oder de Reseau ausgefall ass oder well d\'Format net ënnerstëtzt gëtt.',
  'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'D\'Medien Playback gouf ofgebrach wéinst engem Korruptiounsproblem oder well d\'Medien benotzt Features déi Äre Browser net ënnerstëtzt huet.',
  'No compatible source was found for this media.': 'Keng kompatibel Quell gouf fir dës Medien fonnt.',
  'The media is encrypted and we do not have the keys to decrypt it.': 'D\'Medien sinn verschlësselte a mir hunn net d\'Schlëssel fir se ze entschlësselen.',
  'Play Video': 'Spill Video',
  'Back': 'Zréck',
  'Close': 'Zoumaachen',
  'Close Modal Dialog': 'Zoumaachen Modal Dialog',
  'Modal Window': 'Modal Fënster',
  'This is a modal window': 'Dëst ass eng modal Fënster',
  'This modal can be closed by pressing the Escape key or activating the close button.': 'Dëse Modal kann zougemaach ginn andeems Dir den Escape Schlëssel dréckt oder den Zoumaache Knäppchen aktivéiert.',
  ', opens captions settings dialog': ', mécht den Dialog fir Ënnertitelen Astellungen op',
  ', opens subtitles settings dialog': ', mécht den Dialog fir Ënnertitel Astellungen op',
  ', opens descriptions settings dialog': ', mécht Beschreiwunge Astellungsdialog op',
  ', selected': ', ausgewielt',
  'captions settings': 'Ënnerschrëft Astellungen',
  'subtitles settings': 'Ënnertitel Astellunge',
  'descriptions settings': 'Beschreiwunge Astellunge',
  'Text': 'Text',
  'White': 'wäiss',
  'Black': 'Schwaarz',
  'Red': 'Rout',
  'Green': 'Gréng',
  'Blue': 'Blo',
  'Yellow': 'Giel',
  'Magenta': 'Magenta',
  'Cyan': 'Cyan',
  'Background': 'Hannergrond',
  'Window': 'Fënster',
  'Transparent': 'Transparent',
  'Semi-Transparent': 'Semi-transparent',
  'Opaque': 'Opak',
  'Font Size': 'Schrëftgréisst',
  'Text Edge Style': 'Text Edge Style',
  'None': 'Keen',
  'Raised': 'Opgehuewe',
  'Depressed': 'Depriméiert',
  'Uniform': 'Uniform',
  'Dropshadow': 'Dropshadow',
  'Font Family': 'Schrëftfamill',
  'Proportional Sans-Serif': 'Proportional Sans-Serif',
  'Monospace Sans-Serif': 'Monospace Sans-Serif',
  'Proportional Serif': 'Proportional Serif',
  'Monospace Serif': 'Monospace Serif',
  'Casual': 'Casual',
  'Script': 'Schrëft',
  'Small Caps': 'Kleng Kappen',
  'Reset': 'Zerécksetzen',
  'restore all settings to the default values': 'restauréiert all Astellungen op d\'Default Wäerter',
  'Done': 'Gemaach',
  'Caption Settings Dialog': 'Caption Settings Dialog',
  'Beginning of dialog window. Escape will cancel and close the window.': 'Ufank vun Dialog Fënster. Escape wäert annuléieren an d\'Fënster zoumaachen.',
  'End of dialog window.': 'Enn vun Dialog Fënster.',
  'Watch this video': 'Kuckt dëse Video',
  'Listen to this audio': 'Lauschtert dësen Audio',
  'Also watch': 'Och kucken',
  'Advertisement': 'Annonce',
  'Related': 'Zesummenhang',
  'Social Share': 'Sozial Deelen',
  'Direct link': 'Direkte Link',
  'Open Chromecast menu': 'Open Chromecast Menü',
  'Start AirPlay': 'Start AirPlay',
  'Normal': 'Normal',
  'Automatic': 'Automatesch',
  'Settings': 'Astellungen',
  'Quality': 'Qualitéit',
  'Speed': 'Speed',
  'Up Next': 'Up Next',
  'Copy to clipboard': 'Kopéiert op Clipboard',
  'Copied': 'Kopéiert',
  'Your video will be interrupted for a short moment in': 'Äre Video gëtt fir e kuerze Moment ënnerbrach',
  'seconds': 'Sekonnen',
  'Your video will start soon': 'Äre Video fänkt geschwënn un',
  'Your audio will start soon': 'Ären Audio fänkt geschwënn un',
  'More': 'Méi',
  'Share': 'Deelen',
  'Share this video': 'Deelt dëse Video',
  'Share this podcast': 'Deelt dëse Podcast'
})
