import videojs from 'video.js'
import Html5Http from './conviva/Html5Http'
import Html5Storage from './conviva/Html5Storage'
import Html5Time from './conviva/Html5Time'
import Html5Timer from './conviva/Html5Timer'
import Html5Metadata from './conviva/Html5Metadata'
import window from 'global/window'

const Conviva = window.Conviva
const Plugin = videojs.getPlugin('plugin')

class ConvivaIntgr extends Plugin {
  constructor (player, options) {
    super(player)
    if (Conviva) {
      this.adSessionKey = null
      this.adPlayerStateManager = null
      this.client = null
      this.config = options

      this.initConviva()
    }
  }

  createAdSession (adMetadata) {
    this.adSessionKey = this.client.createAdSession(0, adMetadata)
    this.adPlayerStateManager = this.client.getPlayerStateManager()
    this.client.attachPlayer(this.adSessionKey, this.adPlayerStateManager)
  }

  cleanupAdSession () {
    this.client.releasePlayerStateManager(this.adPlayerStateManager)
    this.client.cleanupSession(this.adSessionKey)
  }

  initConviva () {
    const systemInterface = new Conviva.SystemInterface(
      new Html5Time(),
      new Html5Timer(),
      new Html5Http(),
      new Html5Storage(),
      new Html5Metadata(),
      new Conviva.LoggingInterface()
    )
    this.systemInterface = systemInterface

    const { convivaGatewayUrl, convivaApiKey } = this.config
    const systemSettings = new Conviva.SystemSettings()
    const systemFactory = new Conviva.SystemFactory(systemInterface, systemSettings)
    const clientSettings = new Conviva.ClientSettings(convivaApiKey)
    clientSettings.gatewayUrl = convivaGatewayUrl

    this.client = new Conviva.Client(clientSettings, systemFactory)

    this.player.on('play', () => {
      if (this.player.ads.playing && this.adPlayerStateManager) {
        this.adPlayerStateManager.setPlayerState(Conviva.PlayerStateManager.PlayerState.PLAYING)
      }
    })

    this.player.on('pause', () => {
      if (this.player.ads.playing && this.adPlayerStateManager) {
        this.adPlayerStateManager.setPlayerState(Conviva.PlayerStateManager.PlayerState.PAUSED)
      }
    })

    this.player.on('ads-ad-started', (res) => {
      const adData = this.player.ima.controller.getCurrentAd()

      const { VOD, LIVE } = Conviva.ContentMetadata.StreamType
      const streamType = this.config.isLive ? LIVE : VOD

      const adMetadata = new Conviva.ContentMetadata()
      adMetadata.assetName = adData.getTitle() || 'N/A'
      adMetadata.streamUrl = adData.getMediaUrl() || 'N/A'
      adMetadata.duration = adData.getDuration()
      adMetadata.isLive = this.config.isLive
      adMetadata.streamType = streamType
      adMetadata.applicationName = 'Agnoplay'
      adMetadata.custom = {
        'c3.ad.id': adData.getAdId(),
        'c3.ad.creativeId': adData.getCreativeId(),
        'c3.ad.system': adData.getAdSystem(),
        'c3.ad.isSlate': false
      }

      this.createAdSession(adMetadata)
      this.adPlayerStateManager.setPlayerState(Conviva.PlayerStateManager.PlayerState.PLAYING)
    })

    this.player.on(['adended', 'adscanceled', 'adskip', 'adserror'], (e) => {
      if (e.type === 'adserror') {
        const errorMessage = `Code: ${e.errorCode}, Message: ${e.message}`
        const errorAdMetadata = new Conviva.ContentMetadata()
        errorAdMetadata.assetName = 'Ad Failed'
        this.createAdSession(errorAdMetadata)
        this.client.reportError(this.adSessionKey, errorMessage, Conviva.Client.ErrorSeverity.FATAL)
      }
      this.cleanupAdSession()
    })
  }
}

videojs.registerPlugin('convivaIntgr', ConvivaIntgr)
