import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * Player rate select
 *
 * @extends Component
 * @class MicroRateSelect
 */
class MicroRateSelect extends Component {
  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const el = super.createEl(tag, props, attributes)

    if (this.playbackRates().length > 0) {
      const currentPlaySpeedLabel = this.playbackRates().filter(playSpeed => playSpeed.speed === this.player().playbackRate())
      this.currentRate = Dom.createEl('P', {
        className: 'agnoplayer-micro-rate',
        textContent: currentPlaySpeedLabel[0].label,
        onclick: this.handleButtonClick.bind(this)
      })
      el.appendChild(this.currentRate)

      this.rateSelector = Dom.createEl('P', {
        className: 'agnoplayer-micro-rate-menu'
      })
      el.appendChild(this.rateSelector)
      this.rateSelector = Dom.createEl('P', {
        className: 'agnoplayer-micro-rate-menu'
      })
      el.appendChild(this.rateSelector)
      this.playbackRates().forEach(rate => {
        const item = Dom.createEl('span', {
          id: rate.speed,
          className: 'agnoplayer-micro-rate-menu-item',
          textContent: rate.label,
          onclick: this.handleMenuClick.bind(this, rate.speed)
        })
        this.rateSelector.appendChild(item)
      })
    }

    return el
  }

  playbackRates () {
    return this.options_.playbackRates || this.options_.playerOptions.playbackRates || []
  }

  buildCSSClass () {
    return 'agnoplayer-rate-select'
  }

  handleButtonClick (event) {
    event.stopPropagation()
    Dom.toggleClass(this.rateSelector, 'agnoplayer-micro-rate-menu-opened')
  }

  handleMenuClick (speed, event) {
    event.stopPropagation()
    this.currentRate.textContent = this.playbackRates().filter(rate => rate.speed === speed)[0].label
    Dom.toggleClass(this.rateSelector, 'agnoplayer-micro-rate-menu-opened')
    this.player().playbackRate(speed)
  }
}

Component.registerComponent('MicroRateSelect', MicroRateSelect)
export default MicroRateSelect
