import videojs from 'video.js'
import * as Fn from '../utils/fn.js'

const Component = videojs.getComponent('ClickableComponent')

const UPNEXT_EDGE_TIME = 10
/**
 * UpNext Content control
 *
 * @extends Component
 * @class PlayerNext
 */
class PlayerNext extends Component {
  constructor (player, options) {
    super(player, options)
    this.setEventHandlers_()
    this.updateContent(options.related)
  }

  setEventHandlers_ () {
    this.update = Fn.throttle(Fn.bind(this, this.update), Fn.UPDATE_REFRESH_INTERVAL)

    this.on(this.player_, ['ended', 'durationchange', 'timeupdate'], this.update)
    this.updateInterval = null

    this.on(this.player_, ['playing'], this.enableInterval_)
    this.on(this.player_, ['ended', 'pause', 'waiting'], this.disableInterval_)

    if ('hidden' in document && 'visibilityState' in document) {
      this.on(document, 'visibilityChange', this.toggleVisibility_)
    }
  }

  toggleVisibility_ (e) {
    if (document.hidden) {
      this.disableInterval_()
    } else {
      this.enableInterval_()
      this.update()
    }
  }

  enableInterval_ () {
    if (this.updateInterval) {
      return
    }

    this.updateInterval = this.setInterval(this.update, Fn.UPDATE_REFRESH_INTERVAL)
  }

  disableInterval_ () {
    if (!this.updateInterval) {
      return
    }

    this.clearInterval(this.updateInterval)
    this.updateInterval = null
  }

  isAdPlaying () {
    return (
      this.player_.ads &&
      typeof this.player_.ads.isAdPlaying === 'function' &&
      this.player_.ads.isAdPlaying()
    )
  }

  update () {
    if (this.isDisposed_) {
      return
    }

    try {
      if (this.player_.remainingTime() < UPNEXT_EDGE_TIME && this.player_.remainingTime() !== 0 && !this.isAdPlaying()) {
        this.player_.addClass('vjs-upnext-show')
      } else {
        this.player_.removeClass('vjs-upnext-show')
      }
    } catch (e) {
      // eslint-disable-next-line no-useless-return
      return
    }
  }

  updateContent = (related) => {
    const nextVideo = related?.length ? related[0] : null

    if (nextVideo) {
      this.el().innerHTML = `<div class="vjs-player-upnext-image" style="background-image: url('${nextVideo.image}')"></div>
        <div class="vjs-player-upnext-content">
        <b>${this.localize('Up Next')}</b>
        <div class="vjs-player-upnext-video-title">${nextVideo.title}</div>
      </div>`
    }
  }

  buildCSSClass () {
    return 'vjs-player-upnext'
  }

  handleClick (event) {
    if (this.options_.clickHandler) {
      this.options_.clickHandler.call(this, arguments)
    } else {
      window.location = this.options_.related[0].link
    }
  }

  dispose () {
    if (this.isDisposed_) {
      return
    }
    this.isDisposed_ = true

    this.disableInterval_()

    this.off(this.player_, ['ended', 'durationchange', 'timeupdate'], this.update)
    this.off(this.player_, ['playing'], this.enableInterval_)
    this.off(this.player_, ['ended', 'pause', 'waiting'], this.disableInterval_)

    if ('hidden' in document && 'visibilityState' in document) {
      this.off(document, 'visibilitychange', this.toggleVisibility_)
    }

    super.dispose()
  }
}

Component.registerComponent('PlayerNext', PlayerNext)
export default PlayerNext
