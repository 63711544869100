import videojs from 'video.js'
import * as Fn from '../utils/fn.js'
import ModalContainerCloseButton from './ModalContainerCloseButton.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
* ModalContainer to show a full-screen overlay
*
* @extends Component
* @class ModalContainer
*/
class ModalContainer extends Component {
  constructor (player, options) {
    super(player, options)
    this.wasPlaying_ = options.wasPlaying || false
    this.boundHandleTapClick_ = Fn.bind(this, this.handleTapClick)
    this.addChild(new ModalContainerCloseButton(player, { container: this }))

    // Make sure the contentEl is defined AFTER any children are initialized
    // because we only want the contents of the modal in the contentEl
    // (not the UI elements like the close button).
    this.contentEl_ = Dom.createEl('div', {
      className: 'modal-container-content'
    }, {
      role: 'document'
    })

    this.el_.appendChild(this.contentEl_)
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'agnoplayer-modal-container'
  }

  addEventListenerForItem (component) {
    if (!(component instanceof Component)) {
      return
    }
    this.on(component, ['tap', 'click'], this.boundHandleTapClick_)
  }

  removeEventListenerForItem (component) {
    if (!(component instanceof Component)) {
      return
    }
    this.off(component, ['tap', 'click'], this.boundHandleTapClick_)
  }

  addItem (component) {
    const childComponent = this.addChild(component)
    if (childComponent) {
      this.addEventListenerForItem(childComponent)
    }
  }

  removeChild (component) {
    if (typeof component === 'string') {
      component = this.getChild(component)
    }

    this.removeEventListenerForItem(component)
    super.removeChild(component)
  }

  handleTapClick (event) {
  }

  show () {
    this.player_.addClass('agnoplayer-modal-container-open')
    super.show()
  }

  hide () {
    this.player_.removeClass('agnoplayer-modal-container-open')
    super.hide()
  }

  dispose () {
    this.contentEl_ = null
    this.boundHandleTapClick_ = null
    super.dispose()
  }
}

Component.registerComponent('ModalContainer', ModalContainer)
export default ModalContainer
