import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

class AdCuepoints extends Component {
  constructor (player, options) {
    super(player, options)
    if (!player.hasPlugin('adsManager')) {
      return
    }
    player.on('cuepointchange', this.onCuePointChange.bind(this))
    // sometimes the video duration isn't known yet during the cuepointchange event, so we also listen for durationchange to set the correct videoduration
    player.on('durationchange', this.onDurationChange.bind(this))
  }

  onCuePointChange () {
    if (!this.cuePoints) {
      const cuePoints = this.player().adsManager().getCuePoints()
      this.cuePoints = cuePoints.filter(cuePoint => cuePoint > 0)
    }
    if (!this.videoDuration) {
      this.videoDuration = this.player().duration()
    }
    this.renderCuepoints()
  }

  onDurationChange () {
    this.videoDuration = this.player().duration()
    this.renderCuepoints()
  }

  renderCuepoints () {
    if (this.cuePoints && this.cuePoints.length > 0 && this.videoDuration) {
      for (let i = 0; i < this.cuePoints.length; i++) {
        const el = Dom.createEl('div', { className: 'vjs-marker' })
        el.style.left = this.cuePoints[i] / this.videoDuration * 100 + '%'
        Dom.appendContent(this.el(), el)
      }
    }
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'vjs-marker-container'
  }
}

Component.registerComponent('AdCuepoints', AdCuepoints)
export default AdCuepoints
