import videojs from 'video.js'
import './ModalContainer'
import './ModalSettingsContainer'
import './ContainerButton'
import './TrackContainerButton'
import './TextTrackContainerButton'
import './SubsCapsContainerButton'
import './SettingsContainerButton'
import './AudioTrackContainerButton'

const Component = videojs.getComponent('Component')

/**
 * Control Button Container
 *
 * @extends Component
 * @class ControlButtonContainer
 */
class ControlButtonContainer extends Component {
  constructor (player, options) {
    super(player, options)
    if (options.showProgressBarInMenu) {
      this.addClass('progress-in-menu')
    }
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'agnoplayer-control-button-container'
  }
}

/**
 * Default options for `ControlButtonContainer`
 *
 * @type {Object}
 * @private
 */
ControlButtonContainer.prototype.options_ = {
  children: [
    'chaptersButton',
    'descriptionsButton',
    'subsCapsContainerButton',
    'audioTrackContainerButton',
    'settingsContainerButton',
    'chromecastButton',
    'airPlayButton',
    'fullscreenToggle',
    'playerRelated'
  ]
}

Component.registerComponent('ControlButtonContainer', ControlButtonContainer)
export default ControlButtonContainer
