import videojs from 'video.js'
import './MicroCloseButton'
import './MicroRateSelect'

const Component = videojs.getComponent('Component')

/**
 * Micro Button Container
 *
 * @extends Component
 * @class MicroButtonContainer
 */
class MicroButtonContainer extends Component {
  buildCSSClass () {
    return 'agnoplayer-micro-button-container'
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }
}

/**
 * Default options for `MicroButtonContainer`
 *
 * @type {Object}
 * @private
 */
MicroButtonContainer.prototype.options_ = {
  children: [
    'MicroRateSelect'
  ]
}

Component.registerComponent('MicroButtonContainer', MicroButtonContainer)
export default MicroButtonContainer
