import videojs from 'video.js'

const ModalContainer = videojs.getComponent('ModalContainer')
const Component = videojs.getComponent('Component')

/**
* Settings Container
*
* @extends ModalContainer
* @class ModalSettingsContainer
*/
class ModalSettingsContainer extends ModalContainer {
  constructor (player, options) {
    options = videojs.obj.merge({
      children: ['PlayBackRateSubMenu', 'CloseButton']
    }, options)
    super(player, options)
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return `agnoplayer-modal-container-settings ${super.buildCSSClass()}`
  }
}

Component.registerComponent('ModalSettingsContainer', ModalSettingsContainer)
export default ModalSettingsContainer
