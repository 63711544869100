export function secondsToDuration (seconds) {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)
  let ret = ''

  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`
  }

  ret += `${mins}:${secs < 10 ? '0' : ''}${secs}`
  return ret
}
