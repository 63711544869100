import videojs from 'video.js'
import '../controls/CustomProgressControl'

const Component = videojs.getComponent('Component')
const ProgressControl = videojs.getComponent('ProgressControl')
const Dom = videojs.dom

class PlayerInactiveProgressbar extends ProgressControl {
  buildCSSClass () {
    return 'agnoplayer-inactive-progressbar'
  }

  createEl () {
    return Dom.addClass(
      super.createEl(), this.buildCSSClass()
    )
  }
}

Component.registerComponent('PlayerInactiveProgressbar', PlayerInactiveProgressbar)
export default PlayerInactiveProgressbar
/**/
