import videojs from 'video.js'
import './AdProgressBar'

const Component = videojs.getComponent('Component')

class AdProgressControl extends Component {
  constructor (player, options) {
    super(player, options)
    this.disable()
  }

  disable () {
    this.children().forEach((child) => child.disable && child.disable())
    this.addClass('disabled')
  }

  createEl () {
    return super.createEl('div', {
      className: this.buildCSSClass()
    })
  }

  buildCSSClass () {
    return 'vjs-ad-progress-control vjs-progress-control vjs-control disabled'
  }
}

AdProgressControl.prototype.options_ = {
  children: [
    'adProgressBar'
  ]
}

Component.registerComponent('AdProgressControl', AdProgressControl)
