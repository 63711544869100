class LocalStorageManager {
  get (key) {
    let data = null

    try {
      const rawData = window.localStorage.getItem(key)
      if (rawData) {
        data = JSON.parse(rawData)
      }
    } catch (e) {
      console.error('INCOGNITO_MODE: localStorage is disabled in private/incognito mode', e)
    }

    return data
  }

  set (key, value) {
    try {
      const serializedData = JSON.stringify(value)
      window.localStorage.setItem(key, serializedData)
    } catch (e) {
      console.error('INCOGNITO_MODE: localStorage is disabled in private/incognito mode', e)
    }
  }

  remove (key) {
    try {
      window.localStorage.removeItem(key)
    } catch (e) {
      console.error('INCOGNITO_MODE: localStorage is disabled in private/incognito mode', e)
    }
  }
}

export default new LocalStorageManager()
