import videojs from 'video.js'

/* eslint quote-props: ["error", "as-needed", { "keywords": true, "unnecessary": false }] */
videojs.addLanguage('pt', {
  'Audio Player': 'Tocador de Áudio',
  'Video Player': 'Video Player',
  'Play': 'Toque',
  'Stop': 'Parar',
  'Next': 'Próximo',
  'Pause': 'Pausa',
  'Replay': 'Repetir',
  'Current Time': 'Hora atual',
  'Duration': 'Duração',
  'Remaining Time': 'Tempo restante',
  'Stream Type': 'Tipo de fluxo',
  'LIVE': 'Ao vivo',
  'Loaded': 'Carregado',
  'Progress': 'Progresso',
  'Progress Bar': 'Barra de progresso',
  'progress bar timing: currentTime={1} duration={2}': 'tempo da barra de progresso: currentTime = {1} duração = {2}',
  'Fullscreen': 'Tela cheia',
  'Non-Fullscreen': 'Não-tela inteira',
  'Mute': 'Mudo',
  'Unmute': 'Com som',
  'Playback Rate': 'Taxa de reprodução',
  'Subtitles': 'Legendas',
  'subtitles off': 'legendas desligadas',
  'Captions': 'Legendas',
  'captions off': 'legendas desligadas',
  'Chapters': 'Capítulos',
  'Descriptions': 'Descrições',
  'descriptions off': 'descrições desligadas',
  'Audio Track': 'Faixa de áudio',
  'Volume Level': 'Nível de Volume',
  'You aborted the media playback': 'Você abortou a reprodução de mídia',
  'A network error caused the media download to fail part-way.': 'Um erro de rede causou uma falha parcial no download da mídia.',
  'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'A mídia não pôde ser carregada porque o servidor ou a rede falhou ou porque o formato não é compatível.',
  'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'A reprodução da mídia foi abortada devido a um problema de corrupção ou porque a mídia usava recursos que seu navegador não suportava.',
  'No compatible source was found for this media.': 'Nenhuma fonte compatível foi encontrada para esta mídia.',
  'The media is encrypted and we do not have the keys to decrypt it.': 'A mídia está criptografada e não temos as chaves para descriptografá-la.',
  'Play Video': 'Reproduzir vídeo',
  'Back': 'Voltar',
  'Close': 'Fechar',
  'Close Modal Dialog': 'Fechar diálogo modal',
  'Modal Window': 'Janela Modal',
  'This is a modal window': 'Esta é uma janela modal',
  'This modal can be closed by pressing the Escape key or activating the close button.': 'Este modal pode ser fechado pressionando a tecla Escape ou ativando o botão Fechar.',
  ', opens captions settings dialog': ', abre a caixa de diálogo de configurações de legendas',
  ', opens subtitles settings dialog': ', abre a caixa de diálogo de configurações de legendas',
  ', opens descriptions settings dialog': ', abre a caixa de diálogo de configurações de descrições',
  ', selected': ', selecionado',
  'captions settings': 'configurações de legendas',
  'subtitles settings': 'configurações de legendas',
  'descriptions settings': 'configurações de descrições',
  'Text': 'Texto',
  'White': 'Branco',
  'Black': 'Preto',
  'Red': 'vermelho',
  'Green': 'Verde',
  'Blue': 'Azul',
  'Yellow': 'Amarelo',
  'Magenta': 'Magenta',
  'Cyan': 'Ciano',
  'Background': 'Fundo',
  'Window': 'Janela',
  'Transparent': 'Transparente',
  'Semi-Transparent': 'Semitransparente',
  'Opaque': 'Opaco',
  'Font Size': 'Tamanho da fonte',
  'Text Edge Style': 'Estilo de Borda do Texto',
  'None': 'Nenhum',
  'Raised': 'Criado',
  'Depressed': 'Depressivo',
  'Uniform': 'Uniforme',
  'Dropshadow': 'Dropshadow',
  'Font Family': 'Família de fontes',
  'Proportional Sans-Serif': 'Sans-Serif Proporcional',
  'Monospace Sans-Serif': 'Monospace Sans-Serif',
  'Proportional Serif': 'Serif Proporcional',
  'Monospace Serif': 'Monospace Serif',
  'Casual': 'Casual',
  'Script': 'Roteiro',
  'Small Caps': 'Versalete',
  'Reset': 'Redefinir',
  'restore all settings to the default values': 'restaurar todas as configurações para os valores padrão',
  'Done': 'Feito',
  'Caption Settings Dialog': 'Caixa de diálogo de configurações de legenda',
  'Beginning of dialog window. Escape will cancel and close the window.': 'Início da janela de diálogo. Escape cancelará e fechará a janela.',
  'End of dialog window.': 'Fim da janela de diálogo.',
  'Watch this video': 'Assista esse video',
  'Listen to this audio': 'Ouça este áudio',
  'Also watch': 'Também assistir',
  'Advertisement': 'Propaganda',
  'Related': 'Relacionado',
  'Social Share': 'Compartilhamento Social',
  'Direct link': 'Link direto',
  'Open Chromecast menu': 'Abra o menu do Chromecast',
  'Start AirPlay': 'Iniciar AirPlay',
  'Normal': 'Normal',
  'Automatic': 'Automático',
  'Settings': 'Definições',
  'Quality': 'Qualidade',
  'Speed': 'Velocidade',
  'Up Next': 'A seguir',
  'Copy to clipboard': 'Copiar para área de transferência',
  'Copied': 'Copiado',
  'Your video will be interrupted for a short moment in': 'Seu vídeo será interrompido por um breve momento em',
  'seconds': 'segundos',
  'Your video will start soon': 'O vídeo começa dentro de instantes',
  'Your audio will start soon': 'Seu áudio vai começar em breve',
  'More': 'Mais',
  'Share': 'Compartilhado',
  'Share this video': 'Compartilhe este vídeo',
  'Share this podcast': 'Compartilhe este podcast'
})
