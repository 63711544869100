import videojs from 'video.js'
import ContainerButton from './ContainerButton'
import SettingsContainerMenuItem from './SettingsContainerMenuItem'
import QualityLevelMenuItem from './QualityLevelMenuItem'
import * as Fn from '../utils/fn.js'
import { assign } from '../utils/obj'
import { menuTypes } from '../utils/menuTypes'

const Component = videojs.getComponent('Component')
const PlaybackRateMenuItem = videojs.getComponent('PlaybackRateMenuItem')
const Dom = videojs.dom

class SettingsContainerButton extends ContainerButton {
  constructor (player, options) {
    super(player, options)

    const updateHandler = Fn.bind(this, this.update)
    const closeSettingsMenuHandler = Fn.bind(this, this.closeSettingsMenu)
    const qualityLevels = player.qualityLevels()
    this.selectedMenuType_ = menuTypes.MAIN

    qualityLevels.on('addqualitylevel', updateHandler)
    qualityLevels.on('removequalitylevel', updateHandler)
    this.player_.on('ready', updateHandler)
    this.player_.on('dispose', () => {
      qualityLevels.off('addqualitylevel', updateHandler)
      qualityLevels.off('removequalitylevel', updateHandler)
    })
    this.player_.on('ratechange', closeSettingsMenuHandler)
    this.player_.on('quality_menu_change', closeSettingsMenuHandler)
  }

  buildCSSClass () {
    return `vjs-settings-button ${super.buildCSSClass()}`
  }

  createItems () {
    switch (this.selectedMenuType_) {
      case menuTypes.MAIN:
        return this.createMainMenuItems()
      case menuTypes.QUALITY:
        return this.createQualityMenuItems()
      case menuTypes.PLAYBACKRATE:
        return this.createPlaybackRateMenuItems()
    }
  }

  createContainer () {
    const container = super.createContainer()
    if (this.selectedMenuType_ === menuTypes.QUALITY || this.selectedMenuType_ === menuTypes.PLAYBACKRATE) {
      const backButton = Dom.createEl('div', {
        className: 'vjs-button agnoplayer-button-back',
        innerHTML: this.localize('Back'),
        tabIndex: -1
      })

      const backComponent = new Component(this.player_, { el: backButton })
      backComponent.on('click', () => {
        this.selectMenuType(menuTypes.MAIN)
      })

      container.addChild(backComponent)
    }
    return container
  }

  selectMenuType (menuType) {
    switch (menuType) {
      case menuTypes.MAIN:
        this.title_ = 'Settings'
        break
      case menuTypes.QUALITY:
        this.title_ = 'Quality'
        break
      case menuTypes.PLAYBACKRATE:
        this.title_ = 'Speed'
        break
    }
    this.selectedMenuType_ = menuType
    this.update()
    this.container.show()
  }

  createMainMenuItems () {
    const items = []
    if (this.player_.qualityLevels().length > 1) {
      items.push(new SettingsContainerMenuItem(this.player_, { label: 'Quality', containerButton: this, menuType: menuTypes.QUALITY }))
    }
    items.push(new SettingsContainerMenuItem(this.player_, { label: 'Speed', containerButton: this, menuType: menuTypes.PLAYBACKRATE }))
    return items
  }

  createQualityMenuItems () {
    const qualityLevels = Array.from(this.player().qualityLevels()).sort((a, b) => {
      if (b.height === a.height) {
        return b.bitrate - a.bitrate
      }
      return b.height - a.height
    })
    const items = []
    const enabledQualityLevels = this.player_.qualityLevels().levels_.filter(qualityLevel => qualityLevel.enabled === true)
    items.push(new QualityLevelMenuItem(this.player_, { label: 'Automatic', selected: enabledQualityLevels.length > 1 }))
    for (let i = 0; i < qualityLevels.length; i++) {
      const qualityLevel = qualityLevels[i]
      let label

      if (qualityLevel.label && qualityLevel.label.match(/^[0-9]+p$/) !== null) {
        label = qualityLevel.label
      } else {
        label = qualityLevel.height + 'p'
      }

      items.push(new QualityLevelMenuItem(this.player_, assign(
        qualityLevel,
        {
          label,
          selected: enabledQualityLevels.length === 1 &&
            enabledQualityLevels[0].bitrate === qualityLevel.bitrate &&
            enabledQualityLevels[0].height === qualityLevel.height
        }
      )))
    }
    return items
  }

  createPlaybackRateMenuItems () {
    const rates = this.options_.playbackRates || (this.options_.playerOptions && this.options_.playerOptions.playbackRates)
    const items = []
    for (let i = rates.length - 1; i >= 0; i--) {
      const item = new PlaybackRateMenuItem(this.player(), { rate: rates[i].speed })
      item.selected(rates[i].speed === this.player().playbackRate())
      items.push(item)
    }
    return items
  }

  closeSettingsMenu () {
    // wait a little bit to see what you selected before the menu closes
    this.setTimeout(() => {
      this.container.hide()
      if (this.container.wasPlaying_) {
        this.player().play()
      }
    }, 250)
  }
}
SettingsContainerButton.prototype.controlText_ = 'Settings'
SettingsContainerButton.prototype.options_ = {
  title: 'Settings'
}

Component.registerComponent('SettingsContainerButton', SettingsContainerButton)
export default SettingsContainerButton
