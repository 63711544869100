import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const MuteToggle = videojs.getComponent('MuteToggle')
const Dom = videojs.dom

class BigMuteToggle extends MuteToggle {
  buildCSSClass () {
    return 'agnoplayer-big-mute-toggle'
  }

  updateIcon_ () {
    const vol = this.player_.volume()
    let muted = false
    if (vol === 0 || this.player_.muted()) {
      muted = true
    }
    if (muted) {
      Dom.addClass(this.el_, 'agnoplayer-muted')
    } else {
      Dom.removeClass(this.el_, 'agnoplayer-muted')
    }
  }
}

Component.registerComponent('BigMuteToggle', BigMuteToggle)
export default BigMuteToggle
/**/
