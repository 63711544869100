import videojs from 'video.js'
import { secondsToDuration } from '../utils/secondsToDuration'

const Dom = videojs.dom

const Component = videojs.getComponent('Component')
const PosterImage = videojs.getComponent('PosterImage')

/**
 * Custom PosterImage
 *
 * @extends PosterImage
 * @class CustomPosterImage
 */
class CustomPosterImage extends PosterImage {
  handleClick (event) {
    if (this.player_.ads && this.player_.ima && this.player_.ima.controller && this.player_.ads.isAdPlaying()) {
      this.player_.ima.controller.onAdPlayPauseClick()
      return false
    }
    super.handleClick(event)
  }

  createEl (tag = 'span', props = {}, attributes = {}) {
    const el = super.createEl(tag, props, attributes)
    if (this.options_.playerOptions.duration > 0) {
      this.durationElement = Dom.createEl('span', {
        className: 'vjs-poster-duration',
        textContent: secondsToDuration(this.options_.playerOptions.duration)
      })
      el.appendChild(this.durationElement)
    }
    return el
  }
}

Component.registerComponent('PosterImage', CustomPosterImage)
export default CustomPosterImage
