import videojs from 'video.js'
import './PlayerRelatedItem'
import './CloseButton'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * Related Content control
 *
 * @extends Component
 * @class SocialShareContainer
 */
class SocialShareContainer extends Component {
  constructor (player, options) {
    super(player, options)
    if (this.embeddedItem) {
      this.on(this.embeddedItem, 'click', () => {
        this.openEmbedContainer()
      })
      this.on(this.embedCodeCopy, 'click', () => {
        this.copyEmbedCode()
      })
      this.on(this.backButton, 'click', () => {
        this.closeEmbedContainer()
      })
      this.on(this.closeButton, 'socialshareclosed', () => {
        this.closeEmbedContainer()
      })
    }
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const socialUrl = this.options().url
    const socialItems = this.options().items
    const el = super.createEl(tag, props, attributes)
    const embedItem = socialItems.find(item => item.isEmbeddable)
    const embedUrl = embedItem ? embedItem.url : socialUrl

    this.socialButtonsContainer = Dom.createEl('div', {
      className: 'vjs-social-container'
    })
    Dom.appendContent(el, this.socialButtonsContainer)

    this.embedContainer = Dom.createEl('div', {
      className: 'vjs-embed-container'
    })
    Dom.appendContent(el, this.embedContainer)

    this.embedCode = Dom.createEl('textarea', {
      className: 'vjs-embed-code',
      readOnly: true,
      innerHTML: `<iframe src="${embedUrl}" width="640" height="360" scrolling="no" frameborder="0" allowfullscreen />`
    })
    Dom.appendContent(this.embedContainer, this.embedCode)

    this.embedCodeCopy = Dom.createEl('div', {
      className: 'vjs-embed-code-copy',
      innerHTML: this.player().localize('Copy to clipboard')
    })
    Dom.appendContent(this.embedContainer, this.embedCodeCopy)

    this.backButton = Dom.createEl('button', {
      className: 'vjs-button vjs-embed-code-back'
    })
    Dom.appendContent(this.embedContainer, this.backButton)

    this.backButtonPlaceholder = Dom.createEl('span', {
      className: 'vjs-icon-placeholder'
    })
    Dom.appendContent(this.backButton, this.backButtonPlaceholder)

    this.backButtonText = Dom.createEl('span', {
      className: 'vjs-control-text',
      innerHTML: this.player().localize('Back')
    })
    Dom.appendContent(this.backButton, this.backButtonText)

    const socialButtonsContainer = Dom.createEl('div', {
      className: 'vjs-social-buttons-container'
    })
    Dom.appendContent(this.socialButtonsContainer, socialButtonsContainer)

    const socialButtonsContainerTitle = Dom.createEl('span', {
      className: 'vjs-social-container-title',
      textContent: this.localize('Share this video')
    })

    const socialButtonsContainerTitlePodcast = Dom.createEl('span', {
      className: 'vjs-social-container-title-podcast',
      textContent: this.localize('Share this podcast')
    })

    const relatedSocialButtonsContainerTitle = Dom.createEl('span', {
      className: 'vjs-social-container-title-related',
      textContent: this.localize('Share')
    })

    Dom.appendContent(socialButtonsContainer, socialButtonsContainerTitle)
    Dom.appendContent(socialButtonsContainer, relatedSocialButtonsContainerTitle)
    Dom.appendContent(socialButtonsContainer, socialButtonsContainerTitlePodcast)

    socialItems.forEach((item) => {
      let socialItem
      if (item.isEmbeddable) {
        socialItem = Dom.createEl('a', {
          className: 'vjs-social-button',
          title: item.label,
          innerHTML: `<img src="${item.icon}" alt="${item.label}" width="25" height="25" />`
        })
        if (this.player().hasAdvancedTheme()) {
          socialItem.addEventListener('click', () => {
            // we need the container to be displayed but with opacity 0 otherwise we cannot select the url to copy
            this.embedContainer.classList.add('show-hidden-embed-code')
            if (item.icon_done) {
              socialItem.innerHTML = `<img src="${item.icon_done}" alt="${this.player().localize('Copied')}" width="25" height="25" />`
            }
            socialItem.title = this.player().localize('Copied')
            Dom.addClass(socialItem, 'vjs-copied')
            this.copyEmbedCode()
          })
          socialItem.addEventListener('mouseout', () => {
            socialItem.innerHTML = `<img src="${item.icon}" alt="${item.label}" width="25" height="25" />`
            socialItem.title = item.label
            Dom.removeClass(socialItem, 'vjs-copied')
          })
        } else {
          this.embeddedItem = socialItem
        }
      } else {
        let innerHTML = `<img src="${item.icon}" alt="${item.label}" width="25" height="25" />`
        if (item.icon_hover) {
          innerHTML = `<img src="${item.icon}" alt="${item.label}" width="25" height="25" onmouseover="this.src='${item.icon_hover}'" onmouseout="this.src='${item.icon}'" />`
        }
        socialItem = Dom.createEl('a', {
          className: 'vjs-social-button',
          href: item.src + socialUrl,
          title: item.label,
          target: '_blank',
          innerHTML
        })
      }
      if (item.event) {
        socialItem.addEventListener('click', () => {
          this.player_.trigger(item.event)
        })
      }
      Dom.appendContent(socialButtonsContainer, socialItem)
    })

    const title = Dom.createEl('h5', {
      className: 'vjs-social-title',
      textContent: this.player_.localize('Direct link')
    })

    videojs.on(title, 'click', (event) => {
      const tempInput = document.createElement('input')
      tempInput.value = socialUrl
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      title.textContent = this.player().localize('Copied')
      Dom.addClass(title, 'vjs-copied')
    })

    videojs.on(title, 'mouseout', (event) => {
      title.textContent = this.player().localize('Direct link')
      Dom.removeClass(title, 'vjs-copied')
    })

    Dom.appendContent(this.socialButtonsContainer, title)
    const shareLink = Dom.createEl('div', {
      className: 'vjs-social-link',
      innerHTML: `<a target="_blank" href="${socialUrl}">${socialUrl}</a>`
    })
    Dom.appendContent(this.socialButtonsContainer, shareLink)

    return el
  }

  openEmbedContainer () {
    this.addClass('show-embed-code')
  }

  closeEmbedContainer () {
    this.removeClass('show-embed-code')
  }

  copyEmbedCode () {
    this.embedCode.select()
    document.execCommand('copy')
  }

  copyEmbedCodePlain () {
    this.embedCodePlain.select()
    document.execCommand('copy')
  }

  buildCSSClass () {
    return 'vjs-social-panel'
  }
}

Component.registerComponent('SocialShareContainer', SocialShareContainer)
export default SocialShareContainer
