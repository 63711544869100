import window from 'global/window'
import { newGUID } from './guid.js'

export const UPDATE_REFRESH_INTERVAL = 30

export const throttle = function (fn, wait) {
  let last = window.performance.now()

  const throttled = function (...args) {
    const now = window.performance.now()

    if (now - last >= wait) {
      fn(...args)
      last = now
    }
  }

  return throttled
}

export const bind = function (context, fn) {
  if (!fn.guid) {
    fn.guid = newGUID()
  }

  const bound = fn.bind(context)
  return bound
}

export const debounce = function (func, wait, immediate, context = window) {
  let timeout

  const cancel = () => {
    context.clearTimeout(timeout)
    timeout = null
  }

  /* eslint-disable consistent-this */
  const debounced = function () {
    const self = this
    const args = arguments

    let later = function () {
      timeout = null
      later = null
      if (!immediate) {
        func.apply(self, args)
      }
    }

    if (!timeout && immediate) {
      func.apply(self, args)
    }

    context.clearTimeout(timeout)
    timeout = context.setTimeout(later, wait)
  }
  /* eslint-enable consistent-this */

  debounced.cancel = cancel

  return debounced
}
