import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
* Audio-only header
*
* @extends Component
* @class AudioBar
*/
class AudioBar extends Component {
  constructor (player, options) {
    super(player, options)

    this.hide()
    player.on('videometadataupdated', this.handleVideoMetadataUpdated.bind(this))
    if (player.videoMetadata()) {
      this.handleVideoMetadataUpdated()
    }
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  handleVideoMetadataUpdated () {
    const videoMetadata = this.player_.videoMetadata()
    if (typeof (videoMetadata) === 'undefined') {
      this.hide()
      return
    }

    const title = videoMetadata.title
    const seriesName = videoMetadata.seriesName

    if (title) {
      this.blockEl = Dom.createEl('div', {
        className: 'agnoplayer-audio-bar-wrapper'
      })

      this.titleEl = Dom.createEl('p', {
        className: 'agnoplayer-audio-bar-title',
        textContent: seriesName
          ? `${seriesName} - ${title}`
          : title
      })

      Dom.insertContent(this.blockEl, this.titleEl)
      Dom.insertContent(this.el_, this.blockEl)
      this.show()
      this.setTimeout(() => {
        this.initAnimation()
      }, 100)
    } else {
      this.hide()
    }
  }

  initAnimation () {
    let diffWidth = this.titleEl.offsetWidth - this.el_.offsetWidth

    if (diffWidth > 0) {
      if (window.ResizeObserver) {
        this.observer = new ResizeObserver((_) => {
          diffWidth = this.titleEl.offsetWidth - this.el_.offsetWidth
        })

        this.observer.observe(this.el_)
        this.observer.observe(this.titleEl)
      }

      this.el_.classList.add('agnoplayer-audio-bar-animated')

      let direction = 'right'
      let sideReached = false
      let nextPosition = 0
      const stepInPx = 1

      const makeStep = () => {
        this.timeoutId = setTimeout(() => {
          if (direction === 'right') {
            nextPosition = nextPosition + stepInPx
          } else if (direction === 'left') {
            nextPosition = nextPosition - stepInPx
          }

          // Change dir if reached the end
          if (nextPosition >= diffWidth) {
            nextPosition = diffWidth
            direction = 'left'
            sideReached = true
          } else if (nextPosition <= 0) {
            nextPosition = 0
            direction = 'right'
            sideReached = true
          } else {
            sideReached = false
          }

          this.titleEl.style.setProperty('--marquee-animation-x', `-${nextPosition}px`)

          if (sideReached) {
            this.timeoutId = this.setTimeout(() => {
              makeStep()
            }, 1000)
          } else {
            makeStep()
          }
        }, 60)
      }

      makeStep()
    }
  }

  dispose () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    if (this.observer) {
      this.observer.disconnect()
    }

    super.dispose()
  }

  buildCSSClass () {
    return 'agnoplayer-audio-bar'
  }
}

Component.registerComponent('AudioBar', AudioBar)
export default AudioBar
