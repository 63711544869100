import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const AudioTrackButton = videojs.getComponent('AudioTrackButton')
const AudioTrackMenuItem = videojs.getComponent('AudioTrackMenuItem')
const MenuItem = videojs.getComponent('MenuItem')

/**
 * @extends AudioTrackButton
 */
class CustomAudioTrackButton extends AudioTrackButton {
  createItems (items = []) {
    const item = new MenuItem(this.player_, {
      label: this.controlText_,
      selectable: false,
      multiSelectable: false
    })
    this.on(item, 'click', this.stopPropagation)
    item.addClass('vjs-header-menu-item')
    items.push(item)

    this.hideThreshold_ = 1

    const tracks = this.player_.audioTracks()

    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i]
      const trackItem = new AudioTrackMenuItem(this.player_, {
        track,
        selectable: true,
        multiSelectable: false
      })
      this.on(trackItem, 'click', this.stopPropagation)
      items.push(trackItem)
    }
    // Return an empty array if there is just one audio track available
    if (items.length === 2) {
      return []
    }
    return items
  }

  stopPropagation (event) {
    event.stopImmediatePropagation()
  }
}

Component.registerComponent('AudioTrackButton', CustomAudioTrackButton)
export default CustomAudioTrackButton
