import videojs from 'video.js'
import * as Fn from '../utils/fn'
import { secondsToDuration } from '../utils/secondsToDuration'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom
const ANIMATION_TRIGGER_TYPE = {
  none: 'NONE',
  scroll: 'SCROLL',
  touch: 'TOUCH',
  mouse: 'MOUSE'
}
const ANIMTED_POSTER_FORMAT = {
  gif: 'GIF',
  mp4: 'MP4'
}

/**
 * Animated poster container
 *
 * @extends Component
 * @class AnimatedPosterContainer
 */
class AnimatedPosterContainer extends Component {
  constructor (player, options) {
    super(player, options)

    if (options.animatedPosterUrl && ANIMATION_TRIGGER_TYPE[options.animationTrigger] !== ANIMATION_TRIGGER_TYPE.none) {
      this.options = {
        animatedPosterUrl: options.animatedPosterUrl,
        animatedPosterFormat: ANIMTED_POSTER_FORMAT[options.animatedPosterFormat] || ANIMTED_POSTER_FORMAT.gif,
        animationTrigger: ANIMATION_TRIGGER_TYPE[options.animationTrigger] || ANIMATION_TRIGGER_TYPE.mouse,
        animationStartOffset: parseFloat(options.animationStartOffset),
        animationDuration: options.animationDuration,
        videoDuration: options.videoDuration
      }

      this.handleScrollThrottled = Fn.throttle(Fn.bind(this, this.handleScroll), Fn.UPDATE_REFRESH_INTERVAL)
      this.isHidden = true
      this.inputDelay = 300

      this.addEventListeners()
    }
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const { animatedPosterUrl, animatedPosterFormat } = this.options()

    switch (ANIMTED_POSTER_FORMAT[animatedPosterFormat]) {
      case ANIMTED_POSTER_FORMAT.mp4:
        this.animatedPosterEl = Dom.createEl('video', {
          className: 'vjs-animated-poster-video',
          src: animatedPosterUrl,
          muted: 'muted'
        })
        break
      case ANIMTED_POSTER_FORMAT.gif:
      default:
        this.animatedPosterEl = Dom.createEl('img', {
          className: 'vjs-animated-poster-image',
          src: animatedPosterUrl,
          alt: 'Animated poster'
        })
        break
    }

    this.durationElement = Dom.createEl('span', {
      className: 'vjs-poster-duration',
      textContent: secondsToDuration(this.options.videoDuration)
    })

    const el = super.createEl(tag, props, attributes)
    el.appendChild(this.animatedPosterEl)
    el.appendChild(this.durationElement)

    return el
  }

  buildCSSClass () {
    return 'vjs-animated-poster'
  }

  addEventListeners () {
    const player = this.player().el_

    this.on(this.el_, 'click', this.handleClick)
    this.on(player, 'play', this.handlePlayStart)

    if (this.options.animationTrigger === ANIMATION_TRIGGER_TYPE.mouse) {
      this.on(player, 'mouseenter', this.handleMouseenter)
      this.on(player, 'mouseleave', this.handleMouseleave)
    }

    if (this.options.animationTrigger === ANIMATION_TRIGGER_TYPE.touch) {
      this.on(player, 'touchstart', this.handleTouchstart)
      this.on(player, 'touchend', this.handleTouchend)
    }

    if (this.options.animationTrigger === ANIMATION_TRIGGER_TYPE.scroll) {
      this.on(document, 'scroll', this.handleScrollThrottled)
    }
  }

  showContainer () {
    const player = this.player()

    if (player && !player.hasStarted() && this.isHidden) {
      this.isHidden = false
      player.addClass('vjs-animated-poster-open')

      switch (this.options.animatedPosterFormat) {
        case ANIMTED_POSTER_FORMAT.mp4:
          this.animatedPosterEl.play()
          break
        case ANIMTED_POSTER_FORMAT.gif:
        default:
          this.showTimeoutId = this.setTimeout(() => {
            this.animatedPosterEl.setAttribute('src', this.options.animatedPosterUrl)
          }, 20)
          break
      }
      this.setHideTimeout()
    }
  }

  hideContainer () {
    const player = this.player()

    if (player) {
      player.removeClass('vjs-animated-poster-open')
    }

    switch (this.options.animatedPosterFormat) {
      case ANIMTED_POSTER_FORMAT.mp4:
        if (this.animatedPosterEl) {
          if (!this.animatedPosterEl.paused) {
            this.animatedPosterEl.pause()
          }
          this.animatedPosterEl.currentTime = 0
        }
        break
      case ANIMTED_POSTER_FORMAT.gif:
      default:
        if (this.showTimeoutId) {
          clearTimeout(this.showTimeoutId)
        }
        break
    }
    this.clearHideTimeout()
    this.isHidden = true
  }

  setHideTimeout () {
    this.hideTimeoutId = setTimeout(() => {
      this.hideContainer()
    }, this.options.animationDuration)
  }

  clearHideTimeout () {
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId)
    }
  }

  handleClick () {
    this.player().play()
    this.hideContainer()
  }

  handlePlayStart () {
    this.hideContainer()
  }

  handleMouseenter () {
    this.mouseenterTimeoutId = setTimeout(() => {
      this.showContainer()
    }, this.inputDelay / 2)
  }

  handleMouseleave () {
    if (this.mouseenterTimeoutId) {
      clearTimeout(this.mouseenterTimeoutId)
    }
    setTimeout(() => {
      this.hideContainer()
    }, this.inputDelay / 2)
  }

  handleTouchstart () {
    this.touchstartTimeoutId = setTimeout(() => {
      this.showContainer()
    }, this.inputDelay)
  }

  handleTouchend () {
    if (this.touchstartTimeoutId) {
      clearTimeout(this.touchstartTimeoutId)
    }
    setTimeout(() => {
      this.hideContainer()
    }, this.inputDelay)
  }

  handleScroll () {
    if (this.isOutOfViewport()) {
      if (this.scrollTimeoutId) {
        clearTimeout(this.scrollTimeoutId)
        this.scrollTimeoutId = null
      }

      this.hideContainer()
    } else {
      if (!this.scrollTimeoutId) {
        this.scrollTimeoutId = setTimeout(() => {
          this.showContainer()
        }, this.inputDelay)
      }
    }
  }

  isOutOfViewport () {
    const playerPosition = this.player().el_.getBoundingClientRect()

    return playerPosition.top < 0 ||
      playerPosition.bottom > window.innerHeight * this.options.animationStartOffset
  }
}

Component.registerComponent('AnimatedPosterContainer', AnimatedPosterContainer)
export default AnimatedPosterContainer
