import videojs from 'video.js'

const Button = videojs.getComponent('Button')
const Component = videojs.getComponent('Component')

const Dom = videojs.dom

/**
 * Info button
 *
 * @extends Button
 * @class PodcastInfoButton
 */
class PodcastInfoButton extends Button {
  constructor (player, options) {
    options.infoText = options.playerOptions.podcastDescription
    options.infoTitle = options.playerOptions.posterTitle
    super(player, options)
    this.controlText('Info')
  }

  createEl (tag, props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const el = super.createEl(tag, props, attributes)
    this.infoContainer = Dom.createEl('div', {
      className: 'podcast-info-container vjs-hidden'
    })
    Dom.appendContent(this.player_.el_, this.infoContainer)

    const infoEl = Dom.createEl('span', {
      className: 'podcast-info-text',
      textContent: this.options_.infoTitle
    })

    const titleEl = Dom.createEl('span', {
      className: 'podcast-info-text',
      textContent: this.options_.infoText
    })

    if (this.options_.playerOptions.podcastDescription) {
      Dom.insertContent(this.infoContainer, titleEl)
    } else {
      Dom.insertContent(this.infoContainer, infoEl)
    }

    return el
  }

  buildCSSClass () {
    return 'agnoplayer-icon-info vjs-button'
  }

  handleClick (event) {
    Dom.toggleClass(this.infoContainer, 'vjs-hidden')
  }
}

Component.registerComponent('PodcastInfoButton', PodcastInfoButton)
export default PodcastInfoButton
