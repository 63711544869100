import videojs from 'video.js'

const Plugin = videojs.getPlugin('plugin')

class JumpIntegration extends Plugin {
  constructor (player, options) {
    super(player)
    const JumpKit = window.JumpKit
    if (JumpKit) {
      this.config = options
      JumpKit.insights.setVideoPlayer(player)
      JumpKit.setDebug(false)
      JumpKit.startWithAppKey(this.config.jumpAnalyticsId)
    }
  }
}

videojs.registerPlugin('jumpIntegration', JumpIntegration)
