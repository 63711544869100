import videojs from 'video.js'

const Shaka = videojs.getTech('Shaka')
const Tech = videojs.getTech('Tech')

class AgnoplayerShaka extends Shaka {
  constructor (player, options) {
    super(player, options)
    this.player_.on('play', _ => {
      this.vjsPlayer.trigger('durationchange')
    })
  }

  createEl () {
    const el = super.createEl()
    this.shaka_.configure('manifest.hls.useFullSegmentsForStartTime', true)
    this.shaka_.configure('manifest.availabilityWindowOverride', 1800)
    return el
  }

  duration () {
    if (this.shaka_.isLive()) {
      return Infinity
    }

    return super.duration()
  }

  seekRange () {
    return this.shaka_.seekRange()
  }

  retriggerError (event) {
    const code = event.code
    if (code === 4022) {
      this.vjsPlayer.error({
        code: 4,
        message: `${code} - ${event.message}`
      })

      // 4022 occurs when a master playlist is missing.
      // Reload the stream, while reporting shaka as unusable for this source
      // so the Html5 tech can take over
      AgnoplayerShaka.failedToPlaySource = true
      const sources = this.vjsPlayer.currentSources()
      this.vjsPlayer.src(sources)
    } else {
      super.retriggerError(event)
    }
  }
}

AgnoplayerShaka.isSupported = function () {
  return !AgnoplayerShaka.failedToPlaySource
}
AgnoplayerShaka.defaultState = {}
AgnoplayerShaka.canPlaySource = Shaka.canPlaySource
AgnoplayerShaka.failedToPlaySource = false

Tech.registerTech('AgnoplayerShaka', AgnoplayerShaka)

export default AgnoplayerShaka
