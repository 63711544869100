import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const PlayToggle = videojs.getComponent('PlayToggle')

class PlayerPlayButton extends PlayToggle {
  buildCSSClass () {
    return 'agnoplayer-play-button'
  }
}

Component.registerComponent('PlayerPlayButton', PlayerPlayButton)
export default PlayerPlayButton
