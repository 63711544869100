import videojs from 'video.js'

const Component = videojs.getComponent('Component')
const DurationDisplay = videojs.getComponent('DurationDisplay')

class AdDurationDisplay extends DurationDisplay {
  constructor (player, options = {}) {
    options = videojs.obj.merge({
      replay: false
    }, options)
    super(player, options)
  }

  buildCSSClass () {
    return `vjs-ad-duration ${super.buildCSSClass()}`
  }

  updateContent () {
    // noop
  }

  update (time = 0) {
    this.updateTextNode_(time)
  }
}

Component.registerComponent('AdDurationDisplay', AdDurationDisplay)
