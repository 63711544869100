import videojs from 'video.js'
import TextTrackContainerButton from './TextTrackContainerButton'
import CustomTextTrackMenuItem from './Live/CustomTextTrackMenuItem'

const Component = videojs.getComponent('Component')
const CaptionSettingsMenuItem = videojs.getComponent('CaptionSettingsMenuItem')

class SubsCapsContainerButton extends TextTrackContainerButton {
  constructor (player, options) {
    super(player, options)

    this.label_ = 'subtitles'
    this.controlText(this.label_)
  }

  buildCSSClass () {
    return `agnoplayer-subs-caps-button ${super.buildCSSClass()}`
  }

  createItems () {
    let items = []

    if (!(this.player().tech_ && this.player().tech_.featuresNativeTextTracks) && this.player().getChild('textTrackSettings')) {
      items.push(new CaptionSettingsMenuItem(this.player_, { kind: this.label_ }))
      this.hideThreshold_ += 1
    }

    items = super.createItems(items, CustomTextTrackMenuItem)
    return items
  }
}

SubsCapsContainerButton.prototype.kinds_ = ['captions', 'subtitles']
SubsCapsContainerButton.prototype.controlText_ = 'Subtitles'

Component.registerComponent('SubsCapsContainerButton', SubsCapsContainerButton)
export default SubsCapsContainerButton
